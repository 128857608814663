// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import io from 'socket.io-client';

// const socket = io('http://localhost:5000');

// const Admin = () => {
//   const [number, setNumber] = useState('');
//   const [drawnNumbers, setDrawnNumbers] = useState([]);
//   const [leaderboard, setLeaderboard] = useState([]);
//   const navigate = useNavigate();
//   const [drawnHistory, setDrawnHistory] = useState([]);

//   const [previousDrawnNumbers, setPreviousDrawnNumbers] = useState([]);
//   const [lastCalledNumber, setLastCalledNumber] = useState(null);
 




// useEffect(() => {
//   socket.on('gameState', ({ drawnNumbers, previousDrawnNumbers }) => {
//     setDrawnNumbers(drawnNumbers);
//     setPreviousDrawnNumbers(previousDrawnNumbers);
//   });

//   socket.on('numberDrawn', ({ newNumber }) => {
//     setDrawnNumbers((prev) => [...prev, newNumber]);
//     setLastCalledNumber(newNumber);
//     setPreviousDrawnNumbers((prev) => [...prev, newNumber]);
  
//   });

//   socket.on('leaderboardUpdated', (updatedLeaderboard) => {
//     setLeaderboard(updatedLeaderboard);
//   });

//   socket.on('gameReset', () => {
//     setDrawnNumbers([]);
//     setLastCalledNumber(null);
//   });


//   return () => {
//     socket.off('gameState');
//     socket.off('numberDrawn');
//     socket.off('gameReset');
//     socket.off('leaderboardUpdated');
//   };
// }, []);


//   const handleDrawNumber = () => {
//     // if (drawnNumbers.length < 75 && !drawnNumbers.includes(number)) {
//     // alert("repeated number")
//     // return
//     // }
//     socket.emit('adminDrawNumber', number);
//     setNumber('');
//   };

//   const handleResetGame = () => {
//     socket.emit('resetGame');
//   };

//   const goBack = () => {
//     navigate('/');
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 p-4">
//       <div className="container mx-auto">
//         <h1 className="text-4xl text-center font-bold mb-6">Admin Panel</h1>

//         <div className="mb-4">
//           <input
//             type="number"
//             value={number}
//             onChange={(e) => setNumber(e.target.value)}
//             className="p-3 border rounded-lg"
//             placeholder="Enter number to draw"
//           />
//           <button
//             onClick={handleDrawNumber}
//             className="ml-4 px-6 py-3 bg-blue-500 text-white rounded"
//           >
//             Draw Number
//           </button>
//         </div>

//         <div className="mb-4">
//           <button
//             onClick={handleResetGame}
//             className="px-6 py-3 bg-red-500 text-white rounded"
//           >
//             Reset Game
//           </button>
//         </div>


//         <div className="text-center mt-6">
//           <h3 className="text-xl font-bold">Previous Drawn Numbers</h3>
//           <div className="flex justify-center mt-2">
//             {previousDrawnNumbers.map((num, idx) => (
//               <div
//                 key={idx}
//                 className="px-2 py-1 bg-blue-500 text-white rounded mx-1"
//               >
//                 {num}
//               </div>
//             ))}
//           </div>
//         </div>
// <div>
//         <h3 className="text-xl font-bold">lastCalledNumber</h3>

//         {lastCalledNumber}
//         </div>
//         <div>
//           <h2 className="text-xl font-bold mb-2">Leaderboard</h2>
//           {leaderboard.map((player, idx) => (
//             <div key={idx}>
//               {player.username} - {player.score}
//             </div>
//           ))}
//         </div>

//         <div className="mt-4">
//           <button
//             onClick={goBack}
//             className="px-6 py-3 bg-gray-500 text-white rounded"
//           >
//             Go Back
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Admin;























// import React, { useState, useEffect } from 'react';
// import io from 'socket.io-client';

// const socket = io('http://localhost:5000');

// function AdminPage() {
//   const [drawnNumbers, setDrawnNumbers] = useState([]);
//   const [nextNumber, setNextNumber] = useState('');
//   const [leaderboard, setLeaderboard] = useState([]);

//   useEffect(() => {
//     // Listen for drawn numbers and leaderboard updates from the server
//     socket.on('numberDrawn', ({ newNumber }) => {
//       setDrawnNumbers((prev) => [...prev, newNumber]);
//     });

//     socket.on('leaderboardUpdated', (updatedLeaderboard) => {
//       setLeaderboard(updatedLeaderboard);
//     });

//     socket.on('gameReset', () => {
//       setDrawnNumbers([]);
//     });

//     return () => {
//       socket.off('numberDrawn');
//       socket.off('leaderboardUpdated');
//       socket.off('gameReset');
//     };
//   }, []);

//   const handleDrawNumber = () => {
//     const number = Math.floor(Math.random() * 75) + 1;
//     if (!drawnNumbers.includes(number)) {
//       socket.emit('adminDrawNumber', number);
//       setNextNumber('');
//     } else {
//       alert('Number has already been drawn, please try again.');
//     }
//   };

//   const handleManualDrawNumber = () => {
//     const number = parseInt(nextNumber);
//     if (number > 0 && number <= 75 && !drawnNumbers.includes(number)) {
//       socket.emit('adminDrawNumber', number);
//       setNextNumber('');
//     } else {
//       alert('Invalid number or number already drawn.');
//     }
//   };

//   const handleResetGame = () => {
//     socket.emit('resetGame');
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 p-4">
//       <div className="container mx-auto p-4 bg-white rounded shadow-lg">
//         <h1 className="text-4xl text-center font-bold mb-6">Admin Control Panel</h1>

//         {/* Draw Number Section */}
//         <div className="mb-6">
//           <h2 className="text-2xl font-semibold mb-4">Draw a Number</h2>
//           <button
//             onClick={handleDrawNumber}
//             className="px-6 py-3 bg-blue-500 text-white rounded mr-4"
//           >
//             Draw Random Number
//           </button>
//           <input
//             type="number"
//             min="1"
//             max="75"
//             value={nextNumber}
//             onChange={(e) => setNextNumber(e.target.value)}
//             placeholder="Enter number (1-75)"
//             className="border p-3 mb-4 mr-4 text-black w-32"
//           />
//           <button
//             onClick={handleManualDrawNumber}
//             className="px-6 py-3 bg-green-500 text-white rounded"
//           >
//             Draw Entered Number
//           </button>
//         </div>

//         {/* Reset Game Section */}
//         <div className="mb-6">
//           <h2 className="text-2xl font-semibold mb-4">Game Management</h2>
//           <button
//             onClick={handleResetGame}
//             className="px-6 py-3 bg-red-500 text-white rounded"
//           >
//             Reset Game
//           </button>
//         </div>

//         {/* Drawn Numbers Section */}
//         <div className="mb-6">
//           <h2 className="text-2xl font-semibold mb-4">Drawn Numbers</h2>
//           <div className="grid grid-cols-10 gap-2">
//             {drawnNumbers.map((num, index) => (
//               <div
//                 key={index}
//                 className="p-4 text-center bg-gray-300 rounded-lg"
//               >
//                 {num}
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* Leaderboard Section */}
//         <div>
//           <h2 className="text-2xl font-semibold mb-4">Leaderboard</h2>
//           <div className="bg-gray-200 p-4 rounded">
//             {leaderboard.length === 0 ? (
//               <p>No Bingo achieved yet.</p>
//             ) : (
//               leaderboard.map((user, idx) => (
//                 <div key={idx} className="flex justify-between p-2 border-b">
//                   <span>{user.username}</span>
//                   <span>{user.bingos} Bingos</span>
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AdminPage;
















import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';

// const socket = io('http://localhost:5000');
const socket = io("https://edu.d5art.com", { path: "/socket.io/" });

const Admin = () => {
  const [number, setNumber] = useState('');
  const [drawnNumbers, setDrawnNumbers] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const navigate = useNavigate();
  const [previousDrawnNumbers, setPreviousDrawnNumbers] = useState([]);

  useEffect(() => {
    socket.on('gameState', ({ drawnNumbers }) => {
      setDrawnNumbers(drawnNumbers);
      setPreviousDrawnNumbers(drawnNumbers);
    });

    socket.on('numberDrawn', (num) => {
      setDrawnNumbers((prev) => [...prev, num]);
      setPreviousDrawnNumbers((prev) => [...prev, num]);
    });

    socket.on('leaderboardUpdated', (updatedLeaderboard) => {
      setLeaderboard(updatedLeaderboard);
    });

    socket.on('gameReset', () => {
      setDrawnNumbers([]);
      setPreviousDrawnNumbers([]);
    });

    return () => {
      socket.off('gameState');
      socket.off('numberDrawn');
      socket.off('gameReset');
      socket.off('leaderboardUpdated');
    };
  }, []);

  const handleDrawNumber = () => {
    socket.emit('adminDrawNumber', number);
    setNumber('');
  };

  const handleResetGame = () => {
    socket.emit('resetGame');
  };

  const goBack = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="container mx-auto">
        <h1 className="text-4xl text-center font-bold mb-6">Admin Panel</h1>

        <div className="mb-4">
          <input
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="p-2 border rounded mr-2"
            placeholder="Enter number to draw"
          />
          <button
            onClick={handleDrawNumber}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Draw Number
          </button>
        </div>

        <button
          onClick={handleResetGame}
          className="bg-red-500 text-white px-4 py-2 rounded mb-6"
        >
          Reset Game
        </button>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Drawn Numbers</h2>
          <div className="grid grid-cols-10 gap-2">
            {previousDrawnNumbers.map((num, idx) => (
              <div key={idx} className="p-4 text-center bg-gray-300 rounded-lg">
                {num}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Leaderboard</h2>
          <div className="mt-4">
            {leaderboard.length === 0 ? (
              <p>No bingo achieved yet.</p>
            ) : (
              leaderboard.map((user, idx) => (
                <div key={idx} className="flex justify-between p-2 border-b">
                  <span>{user.username}</span>
                  <span>{user.bingos} Bingos</span>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="text-center">
          <button onClick={goBack} className="text-blue-500">Go Back</button>
        </div>
      </div>
    </div>
  );
};

export default Admin;




