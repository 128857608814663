import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Confetti from 'react-confetti';
import { toast, Toaster } from 'react-hot-toast';

 //const socket = io('http://localhost:5000');
const socket = io("https://edu.d5art.com", { path: "/socket.io/" });

function App() {
  const [username, setUsername] = useState('');
  const [bingoCard, setBingoCard] = useState([]);
  const [drawnNumbers, setDrawnNumbers] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [showPopup, setShowPopup] = useState(true);
  const [bingoAchieved, setBingoAchieved] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  

  useEffect(() => {
    socket.on('initialGameState', (initialState) => {
      setDrawnNumbers(initialState.drawnNumbers || []);
    });

    socket.on('numberDrawn', (num) => {
      setDrawnNumbers((prevNumbers) => [...prevNumbers, num]);
    });

    socket.on('leaderboardUpdated', (updatedLeaderboard) => {
      setLeaderboard(updatedLeaderboard);
    });

    socket.on('gameReset', resetGameState);

    socket.on('bingoAchieved', () => {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    });

    return () => {
      socket.off('initialGameState');
      socket.off('numberDrawn');
      socket.off('leaderboardUpdated');
      socket.off('gameReset');
      socket.off('bingoAchieved');
    };
  }, []);

  const resetGameState = () => {
    setDrawnNumbers([]);
    setSelectedNumbers([]);
    setBingoAchieved(false);
  };

  const generateBingoCard = () => {
    const numbers = Array.from({ length: 75 }, (_, i) => i + 1);
    const shuffledNumbers = numbers.sort(() => Math.random() - 0.5);
    const card = shuffledNumbers.slice(0, 25);
    setBingoCard(card);
  };

  const handleSubmitUsername = () => {
    if (!username.trim()) {
      toast.error('Please enter a valid username');
      return;
    }
    setShowPopup(false);
    socket.emit('joinGame', username);
    generateBingoCard();
  };



  useEffect(() => {
    console.log("Selected numbers changed:", selectedNumbers);
    checkBingo(selectedNumbers);
  }, [selectedNumbers]);

// Handle number strike by the user
const handleStrikeNumber = (num) => {
  console.log("User clicked number:", num);
  console.log("Current drawn numbers:", drawnNumbers);
  console.log("Current selected numbers:", selectedNumbers);

  // Convert num to string to match the drawn numbers' format
  if (drawnNumbers.includes(num.toString()) && !selectedNumbers.includes(num)) {
    setSelectedNumbers((prevSelectedNumbers) => {
      const newSelectedNumbers = [...prevSelectedNumbers, num];
      console.log("Updated selected numbers:", newSelectedNumbers);
      return newSelectedNumbers;
    });
  } else if (!drawnNumbers.includes(num.toString())) {
    toast.error('You can only strike drawn numbers!');
  }
};

const winningPatterns = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [15, 16, 17, 18, 19],
  [20, 21, 22, 23, 24],
  [0, 5, 10, 15, 20],
  [1, 6, 11, 16, 21],
  [2, 7, 12, 17, 22],
  [3, 8, 13, 18, 23],
  [4, 9, 14, 19, 24],
  [0, 6, 12, 18, 24],
  [4, 8, 12, 16, 20],
];

let detectedPatterns = new Set();

const checkBingo = (newSelectedNumbers) => {
  console.log("Checking bingo for selected numbers:", newSelectedNumbers);

  const selectedNumbersStr = newSelectedNumbers.map(num => num.toString());

  let bingoFound = false;
  console.log("Winning patterns:", winningPatterns);
  console.log("Current Bingo Card:", bingoCard);

  for (let pattern of winningPatterns) {
    const selectedPatternNumbers = pattern.map(index => bingoCard[index]);

    if (detectedPatterns.has(pattern.toString())) {
      continue;
    }

    if (selectedPatternNumbers.includes(undefined)) {
      console.error("Undefined value in bingo card at indices:", pattern);
      continue;
    }

    console.log("Checking pattern:", selectedPatternNumbers);

    const allSelected = selectedPatternNumbers.every(num => selectedNumbersStr.includes(num.toString()));
    console.log("Pattern:", selectedPatternNumbers, "All selected:", allSelected);

    if (allSelected) {
      bingoFound = true;
      detectedPatterns.add(pattern.toString()); // Mark this pattern as detected
      console.log("Bingo Detected on pattern:", selectedPatternNumbers);
      break; // Exit as soon as we find a bingo
    }
  }

  if (bingoFound && !bingoAchieved) {
    console.log("Bingo Achieved for the first time!");
    setBingoAchieved(true);
    socket.emit('bingo', username); // Notify server about bingo
    incrementBingoCount(username); // Increment bingo count
  } else if (!bingoFound) {
    console.log("No bingo found yet.");
  } else if (bingoAchieved) {
    setBingoAchieved(true);
    socket.emit('bingo', username); // Notify server about bingo
    incrementBingoCount(username);
    console.log("Bingo already achieved, no additional action taken.");
  }
};




  
  const incrementBingoCount = (username) => {
    const updatedLeaderboard = leaderboard.map((user) => {
      if (user.username === username) {
        const newBingoCount = user.bingos;
        if (newBingoCount >= 5) {
        //  alert(`${username}, you are the winner with ${newBingoCount} bingos!`);
        }
        return { ...user, bingos: newBingoCount };
      }
      return user;
    });

    setLeaderboard(updatedLeaderboard);
  };

  return (
    <div style={{backgroundImage: 'url(./assets/background.jpeg)'}} className="min-h-screen overflow-hidden overflow-y-auto font-poppins bg-cover bg-center w-full text-white p-4">
    <Toaster/>
      {/* {showConfetti && <Confetti />} */}
      {showPopup && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10">
          <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col justify-center items-center">
            <h2 className="w-full text-xl font-bold mb-4 text-purple-900 text-left">Enter Username</h2>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Your username"
              className="focus:outline-none border-b p-3 mb-4 text-black w-72"
            />
            <br/>
            <button onClick={handleSubmitUsername} className="px-5 py-2 font-semibold bg-purple-800 text-white rounded">
              Submit
            </button>
          </div>
        </div>
      )}

      <div className="container mx-auto p-4">
        <div className='flex justify-center items-center' >
        <div className='mb-6 bg-blue-800 p-3 rounded-lg'>
        <h1 className="text-4xl text-center font-bold ">Bingo Game</h1>
</div>
</div>
        <div className="lg:w-[35%] sm:w-[65%] mx-auto grid grid-cols-5 gap-2 mb-6">
          {bingoCard.map((num, index) => (
            <div
              key={index}
              className={`p-4 text-center text-black font-semibold border rounded-lg ${selectedNumbers.includes(num) ? 'bg-blue-800  text-white' : 'bg-gray-200'}`}
              onClick={() => handleStrikeNumber(num)}
            >
              {num}
            </div>
          ))}
        </div>
        <div className='flex justify-center items-center rounded-xl' >
        <div className="lg:w-[70%]  w-[95%] mx-auto mb-6 backdrop-blur-xl bg-black/30 rounded-xl p-5">       
        <div className=''>
          <h2 className="text-2xl w-full text-center font-semibold text-white underline">Drawn Numbers</h2>
          </div>
          <div className="flex justify-center items-center flex-wrap gap-2 mt-5 rounded-xl">
            {drawnNumbers.map((num, index) => (
              <div key={index} className="w-9 h-9 text-sm flex justify-center items-center text-center bg-blue-800 rounded-full">
               <p className='font-bold' >{num}</p>
              </div>
            ))}
          </div>
        </div>
        </div>

        <div className="text-center mt-8">
          <h2 className="text-xl font-bold">User List</h2>
          <div className="w-[70%] mx-auto mt-4 backdrop-blur-xl bg-black/30 h-80 overflow-hidden overflow-y-auto rounded-xl p-5">
            {leaderboard.length === 0 ? (
              <p>No bingo achieved yet.</p>
            ) : (
              leaderboard.map((user, idx) => (
                <div key={idx} className=" flex justify-between p-3 border-b border-white/30">
                  <div className='flex items-center gap-3' >
                  <p className='text-sm' >{idx + 1 }.</p>
                  <p className='font-semibold' >{user.username}</p>
                  {/* <span>{user.bingos} Bingos</span> */}
                  </div >
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;