import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Landing from './Components/Pages/Landing';
import Faq from './Components/Pages/Faq';
import NotFound from './Components/Pages/NotFound';
import DnaHome from './Components/Pages/DNA/DnaHome';
import DnaTracking from './Components/Pages/DNA/DnaTracking';
import Bingo from './Components/Pages/Game/Bingo';
import BingoAdmin from './Components/Pages/Game/BingoAdmin';
import Bingogame from './Components/Pages/Game/Game';



function Routers() {

  const subdomain = window.location.host.split('.')[0];
  const isGame = subdomain === 'game';

  console.log("isGame",isGame)

    return (

      <div>
        {!isGame ? (
      
      <Routes>
      <Route path="/" element={<Landing/>} />
      <Route path="/Faq" element={<Faq/>} />
       <Route path="/dna-home" element={<DnaHome/>} />
       <Route path="*" element={<NotFound />} />

       <Route path="/dna-track" element={<DnaTracking/>} />
      </Routes>
      ):(


  <Routes>
       <Route path="/" element={<Bingogame/>} />
       <Route path="/game-track" element={<BingoAdmin/>} />
       <Route path="*" element={<NotFound />} />

       </Routes>
 )}

       </div>
     
     
  );
}

export default Routers;
