
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast'; // Assuming react-hot-toast is installed
// import ReCAPTCHA from "react-google-recaptcha";
import RecaptchaComponent from '../Recaptcha';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';


export default function DNATracking() {
  const [trackingId, setTrackingId] = useState('');
  const [trackingInfo, setTrackingInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null); // To store recaptcha token
  const recaptchaRef = useRef();
  const reCaptcha_public = "6LeDPCoqAAAAAH7ABnqmmz3q7YvSZIXZxbRE4mN2"

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const trkId = urlParams.get('trk');
    if (trkId) {
      setTrackingId(trkId);
    }
  }, []);

  // const expireRecaptchaToken = () => {
  //   if (recaptchaRef.current) {
  //     recaptchaRef.current.reset();
  //     setRecaptchaToken(null);
  //     console.log("reCAPTCHA token expired")
  //     //   toast.error("reCAPTCHA token expired");
  //   }
  // };

  const handleTokenExpiration = () => {
    console.log("Token expired on Login page");
    setRecaptchaToken(null);
    if (recaptchaRef.current) recaptchaRef.current.resetRecaptcha(); // Reset reCAPTCHA explicitly
  };

  const fetchTrackingInfo = async (trackingId) => {
    setIsLoading(true);
    setError('');

    if (!recaptchaToken) {
      setError("Please complete the reCAPTCHA.");
      toast.error("Please complete the reCAPTCHA.", {
        icon: '⚠️',
      });
      setIsLoading(false);

      return;
    }

    try {
      const response = await axios.post(
        `https://edu.d5art.com/api/get-dna-tracking/${trackingId}`,
        { recaptchaToken }
      );

      console.log("response", response)



      if (response.data.trackingInfo.length === 0) {
        toast.error("Tracking ID not found.", {
          icon: '🚨',
        });
      } else {
        setTrackingInfo(response.data.trackingInfo);
        toast.success("Tracking details fetched successfully!", {
          icon: '📦',
        });
      }
    } catch (error) {

      console.error("Failed to fetch tracking info.", error);
      if (error.response && error.response.status === 400 && error.response.data === "ReCaptcha failed") {
        toast.error("ReCaptcha validation failed. Please try again.", {
          icon: '❌',
        });
        setError("ReCaptcha validation failed. Please try again.");
        // expireRecaptchaToken();
        handleTokenExpiration()
      } else {
        toast.error("Failed to fetch tracking info. Please try again later.", {
          icon: '❌',
        });
        console.error("Failed to fetch tracking info.", error);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (trackingId.trim() !== '') {
      fetchTrackingInfo(trackingId);
    } else {
      setError("Please enter a valid tracking ID.");
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const stages = ["Order Placed", "Arriving to Home", "User Posted", "Moving to Lab", "Testing", "Result"];

  return (
    <div className='bg-[#1A1919] '>  <Header/>
    <div className="min-h-screen  font-poppins flex flex-col items-center justify-center bg-[#1A1919] p-6 text-white bg-cover bg-center relative overflow-hidden" style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
      <Toaster />
    
      <div data-aos='fade-in' data-aos-duration='1000' className="w-full max-w-md bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px] shadow-md z-10">
        <div className="p-6 bg-[#1A1919] rounded-xl bg-cover bg-center" style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
          <div className='flex justify-center'>
            <h1 className='text-2xl md:text-3xl 2xl:text-4xl font-bold text-transparent bg-gradient-to-r from-[#31989B] to-[#4A5CA4] bg-clip-text '
            >DNA Tracking</h1>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4 mt-6">
            <div className="space-y-2">
              <label htmlFor="trackingId" className="text-sm font-semibold text-[#F29D38]">
                Enter Tracking ID
              </label>
              <input
                type="text"
                id="trackingId"
                value={trackingId}
                onChange={(e) => setTrackingId(e.target.value)}
                placeholder="Enter your tracking ID"
                className="w-full px-4 py-2 bg-white/10  text-white placeholder-gray-400 rounded-md outline-none"
              />
            </div>
            <div className="mt-4 flex justify-center">
              {/* <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={reCaptcha_public}// Replace with your actual site key
                onChange={handleRecaptchaChange}
                className="scale-90"
              /> */}
              
        <RecaptchaComponent 
            ref={recaptchaRef}

          onTokenGenerated={handleRecaptchaChange}
          onTokenExpired={handleTokenExpiration}
        />
            </div>
            <button
              type="submit"
              className="w-full py-3 font-semibold font-play bg-[#d9d9d9]/35 backdrop-blur-md text-white rounded-md duration-500 active:translate-y-1"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Track DNA"}
            </button>
          </form>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div data-aos='fade-in' data-aos-duration='1500' className="mt-4 text-red-400 flex items-center z-10">
          <svg className="mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4v.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          {error}
        </div>
      )}

      {/* Tracking Info */}
      {trackingInfo.length > 0 && (
        <div data-aos='fade-in' data-aos-duration='1000' className="mt-8 w-full max-w-4xl bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px] shadow-md z-10">
          <div className="p-6 bg-[#1A1919] rounded-xl bg-cover bg-center" style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
            <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center">
              {stages.map((stage, index) => {
                const isCompleted = trackingInfo.some((track) => track.status === stage);
                const isCurrentStep = index === trackingInfo.length;

                return (
                  <div key={index} className="flex flex-col items-center space-y-2">
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center
                        ${isCompleted ? 'bg-green-500' : (isCurrentStep ? 'bg-yellow-500' : 'bg-gray-600')}
                        transition-all duration-500 ease-in-out`}
                    >
                      {isCompleted && <svg className="text-white w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>}
                    </div>
                    <span className={`text-xs ${isCompleted ? 'text-green-400' : 'text-gray-400'} text-center max-w-[60px]`}>
                      {stage}
                    </span>
                    {isCompleted && (
                      <span className="text-[10px] text-gray-500">
                        {trackingInfo.find(track => track.status === stage)?.date_time}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="mt-6 h-2 bg-[#F29D38]/20 rounded-full overflow-hidden">
              <div
                className="h-full bg-[#F29D38] transition-all duration-1000 ease-in-out"
                style={{ width: `${(trackingInfo.length / stages.length) * 100}%` }}
              />
            </div>
          </div>
        </div>
      )}
      <div className='absolute flex justify-around z-0'>
        <div className='bg-[#00FFC2]/5 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
        <div className='bg-[#7B00FF]/10 blur-3xl w-[20rem] h-[20rem] rounded-full'></div>
        <div className='bg-[#4B53FC]/15 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
      </div>
    
    </div>
    <Footer />
    </div>
  );
}








