import React from "react";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NavLink, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { set } from "mobx";
import SliderTesty from "../Sliders/SliderTesty";
import CountUp from 'react-countup';
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init();
export default function Landing() {
  const [moveImage, setMoveImage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [isActive, setActive] = useState('0')

  const specifiedCourseIds = [1, 10];

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('https://edu.d5art.com/api/Allcourses');
        const data = await response.json(); 
      


        const availableCourses = data.filter((course) => course.status !== 'disabled');

        const specifiedSet = new Set(specifiedCourseIds);

        const orderedCourses = specifiedCourseIds
          .map((id) => availableCourses.find(course => course.course_id === id))
          .filter(Boolean); 

        const remainingCourses = availableCourses.filter(course => !specifiedSet.has(course.course_id));

        const finalCourses = [...orderedCourses, ...remainingCourses];

        setCourses(finalCourses);



      } catch (error) {
        console.error('Error fetching courses:', error);
      }
      finally {
        // setLoading(false);  
      }
    };

    fetchCourses();
  }, []);

  const handleTabClick = (tab) => {
    setActive(tab);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevTab) => {
        const nextTab = (parseInt(prevTab) + 1) % 4;
        return nextTab.toString();
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const truncateDescription = (description) => {
    const words = description.split(" ");
    const truncatedDescription = words.slice(0, 20).join(" ");
    const showMore = words.length > 20;
    return showMore ? `${truncatedDescription}.....` : truncatedDescription;
  };
  const [email, setEmail] = useState('');

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
    submission_type: "reach_us"
  });

  const handleSubmitChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { first_name, last_name, email, phone, message, submission_type } = formData;
    if (!first_name || !last_name || !email || !phone || !message) {
      toast.error("All fields are required");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }



    try {

      const response = await fetch('https://edu.d5art.com/api/form-submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      console.log("response", response)


      if (response.ok) {
        toast.success("Submitted successfully");
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          message: ''
        });
      } else {
        toast.error('Failed to send your message.');
      }


    } catch (error) {
      console.log("error", error)
      toast.error('Something went wrong.');

    }

  };



  const handleJoinWaitlist = async () => {
    if (!email) {
      toast.error('Please enter an email!');
      return;
    }

    const data = {
      submission_type: 'waitlist',
      email: email
    };

    try {
      const response = await fetch('https://edu.d5art.com/api/form-submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      console.log("response", response)

      if (response.ok) {
        toast.success('Be an Early Bird! Successfully joined the waitlist.');
        setEmail('');
      } else {
        toast.error('Failed to join the waitlist.');
      }
    } catch (error) {
      console.log("error", error)

      toast.error('Something went wrong.');
    }
  };


  const imageList = [
    { src: "./assets/S1.png" },
    { src: "./assets/S2.png" },
    { src: "./assets/S3.png" },
    { src: "./assets/S4.png" },
    { src: "./assets/S5.png" },
    { src: "./assets/S6.png" },
    { src: "./assets/S7.png" },
    { src: "./assets/S8.png" },
    { src: "./assets/S9.png" },
    { src: "./assets/S10.png" },
    { src: "./assets/S11.png" },
    { src: "./assets/S12.png" },
    { src: "./assets/S13.png" },
  ];

  const handleSearch = () => {
    const url = `https://edu.d5art.com/courses?search=${encodeURIComponent(
      searchTerm
    )}`;
    window.location.href = url;
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const moveInterval = setInterval(() => {
      setMoveImage((prevMove) => !prevMove);
    }, 1500); // Adjust the interval as needed

    return () => {
      clearInterval(moveInterval);
    };
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 734,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 7000);

    return () => clearInterval(timer);
  }, []);

  const handleBlog = (url) => {
    const fullUrl = url.startsWith("http") ? url : `https://${url}`;
    window.open(fullUrl, "_blank");
  };

  const teamMembers = [
    {
      name: "Sakthi Visakan Rajaguru",
      role: "CEO",
      imageSrc: "./assets/Wiz1.png",
    },
    {
      name: "Annamalai Palaniappan",
      role: "COO",
      imageSrc: "./assets/Wiz2.png",
    },
    {
      name: "Anup Kumar",
      role: "CTO",
      imageSrc: "./assets/Wiz3.png",
    },
    {
      name: "Gaurav Sharma",
      role: "CFO",
      imageSrc: "./assets/Wiz4.png",
    },
    {
      name: "Khalid Al Mansouri",
      role: "BOARD ADVISOR",
      imageSrc: "./assets/Wiz5.png",
    },
    {
      name: "Alun Richards",
      role: "BOARD ADVISOR",
      imageSrc: "./assets/Wiz6.png",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? teamMembers.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === teamMembers.length - 1 ? 0 : prevIndex + 1
    );
  };


  return (
    <div>
      <div
        className="bg-[#1A1919] font-poppins min-h-screen "
        style={{ backgroundImage: "url('./assets/FABC.png')" }}
      >
        <Header />
        <Toaster />
        <div className="Section-1">
          <div className="w-full md:w-[100%] pt-7 md:pt-14 px-7 md:px-10">
            <div className="flex justify-between lg:justify-around h-full">
              <div className="h-full flex items-center lg:translate-y-24">
                <img
                  style={{
                    transition: "transform 0.9s z-0",
                    transform: moveImage ? "translateY(25px)" : "translateY(0)",
                  }}
                  className=""
                  src="./assets/image 100.png"
                  alt="circle1"
                ></img>
              </div>
              <div
                className=""
                style={{
                  transition: "transform 2s ",
                  transform: moveImage ? "translateY(45px)" : "translateY(0)",
                }}
              >
                <img src="./assets/Ellipse 428.png" alt="circle2"></img>
              </div>
              <div
                className="hidden md:block"
                style={{
                  transition: "transform 0.1s ",
                  transform: moveImage ? "translateY(20px)" : "translateY(0)",
                }}
              >
                <img src="./assets/Ellipse 429.png" alt="circle2"></img>
              </div>
            </div>
            <div className="z-10">
              <p className="text-white text-center font-bold tracking-widest text-3xl lg:text-4xl ">
                Unlock the Gate way to your{" "}
                <span
                  style={{
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    backgroundImage:
                      "linear-gradient(to right, #0073FF,#1CD9FF)",
                  }}
                >
                  WEB3{" "}
                </span>
                Destiny
                <br></br>{" "}
                {/* <span className="text-2xl"> Your Knowledge, Your Asset.</span>{" "} */}
              </p>
            </div>
          </div>
          <div className="flex justify-center mt-10">
            <p className="w-[90%] md:w-[40%] font-museomoderno text-center text-white font-musemo text-lg">
              Forge your path in the Web3 universe, where your skills are your currency and your patential knows no bounds. Discover a realm of limitless career opportunites
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-7 mt-7">
            <a href="https://ex.d5art.com/Register" className="mx-auto md:mx-0">
              <button className="bg-gradient-to-r from-[#00768B] to-[#0E2DA7] rounded-full p-[2px]">
                <button className="bg-[#1A1919] px-5 py-2 w-[200px] rounded-full text-white font-museomoderno">My Passport</button>
              </button>
            </a>
            <a href="https://ex.d5art.com/Register" className="mx-auto md:mx-0">
              <button className="bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00] rounded-full p-[2px]">
                <button className="bg-[#1A1919] px-5 py-2 w-[200px] rounded-full text-white font-museomoderno">Explore Skill Realm</button>
              </button>
            </a>
          </div>
          <div className="flex flex-col lg:flex-row gap-5 md:gap-0">
            <div
              className="lg:w-[30%] flex justify-center bg-cover bg-center"
              style={{ backgroundImage: 'url("./assets/Ellipse 3 (1).png")' }}
            >
              <img className="w-[75%]" src="./assets/avatar12.png"></img>
            </div>
            <div
              className="lg:w-[40%] grid content-around bg-cover bg-center"
              style={{ backgroundImage: 'url("./assets/Ellipse 1 (1).png")' }}
            >
              <div className="flex justify-center items-center text-2xl text-center lg:text-start font-museomoderno mt-5 md:mt-0 p-5">
                <div className="w-[30%] lg:w-[40%]">
                  <p className=" text-[#FFBA00]"><CountUp end={22000} duration={2.5} separator=',' />+</p>
                  <p className="text-white font-musemo text-base text-wrap">Enrolled Users</p>
                </div>
                <div className="w-[30%] lg:w-[40%] flex justify-center">
                  <div>
                    <p className="text-[#FFBA00]"><CountUp end={50} duration={2.5} />+</p>
                    <p className="text-white font-musemo text-base">
                      Global Partnership
                    </p>
                  </div>
                </div>
                <div className="w-[30%] lg:w-[40%] lg:flex lg:justify-end">
                  <div>
                    <p className="text-[#FFBA00]"><CountUp end={100} duration={2.5} />+</p>
                    <p className="text-white font-musemo text-base">Skill Passports</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="lg:w-[30%] flex justify-center bg-cover bg-no-repeat bg-center"
              style={{ backgroundImage: 'url("./assets/Ellipse 6.png")' }}
            >
              <img className="w-[70%]" src="./assets/image 51.png"></img>
            </div>
          </div>
          <div>
            <div className="mt-5">
              <p className="text-white font-museomoderno text-center text-2xl ">
                Trusted By
              </p>
            </div>
            <div className="flex justify-center px-3 lg:px-0">
              <Slider
                className="mt-3 w-[90%]"
                {...settings}
                prevArrow={<></>}
                nextArrow={<></>}
              >
                {imageList.map((image, index) => (
                  <div key={index} className="grid content-center h-[150px]">
                    <img
                      className="m-auto p-3 md:p-2"
                      src={image.src}
                      alt={`slider-image-${index}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <div className="Section-2 min-h-screen relative flex justify-center items-center">
          <div>
            <div className="z-10">
              <p className="text-[#FFBA00] z-10 font-museomoderno font-bold text-center text-3xl">
                Forge your Virtual Identity
              </p>
            </div>
            <div className="mt-5 flex flex-col lg:flex-row md:pb-10 ">
              <div className="lg:w-[50%] flex font-nerko text-3xl">
                <div
                  className="w-[30%] mt-20 bg-cover bg-center bg-no-repeat"
                  style={{ backgroundImage: 'url("./assets/Ellipse 7.png")' }}
                >
                  <img src="./assets/image 49.png"></img>
                  <p className="font-musemo text-gray-500 text-center">inam</p>
                </div>
                <div
                  className="w-[30%] bg-contain bg-center bg-no-repeat"
                  style={{ backgroundImage: 'url("./assets/Ellipse 7.png")' }}
                >
                  <img src="./assets/image 50 (1).png"></img>
                  <p className="font-musemo text-gray-500 text-center">Puna</p>
                </div>
                <div
                  className="w-[30%] mt-20 bg-contain bg-right bg-no-repeat"
                  style={{ backgroundImage: 'url("./assets/Ellipse 7.png")' }}
                >
                  <img src="./assets/image 98.png"></img>
                  <p className="font-musemo text-gray-500 text-center">Edith</p>
                </div>
              </div>
              <div className="lg:w-[50%] px-3 lg:px-0">
                <div className="w-full md:w-[100%] ">
                  <div className="flex justify-around h-full">
                  </div>
                </div>
                <div className="flex px-5 mt-10">
                  <p className="text-lg text-white lg:w-[80%] leading-8">
                    "Shatter traditional hiring barriers with your {" "}
                    <span className="text-transparent bg-clip-text font-bold bg-[#FFBA00]">
                      D5art ID.{" "}
                    </span>
                    Let your skills guide you as your {" "}
                    <span className="text-transparent bg-clip-text bg-gradient-to-r font-bold from-[#FFBA00] to-[#0EA4A9]">
                      D5art Passport reveals your true potential in the Web3 realm.{" "}
                    </span>
                    Embark on a Skill Quest to acquire new abilities and validate your expertise, where unbiased hiring ensures that only your talents shine, unlocking paths to opportunities based on merit."
                  </p>
                </div>
                <div className="w-full md:w-[100%] relative">
                  <div className="flex justify-center md:justify-between px-5 h-full">
                    <NavLink to="https://ex.d5art.com/SeekerRegistration">
                      <div className="flex justify-start mt-8">
                        <button className="flex pt-3 pb-1 px-2 bg-[#1A1919] z-20 rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white">
                          <button className="p-2 rounded-full translate-y-0.5 group-hover:translate-x-[119px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5"></button>
                          <p className="-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out">
                            Create
                          </p>
                          <button className="ml-5 p-3.5 rounded-full -translate-y-1 border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                        </button>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="Bouncing Sphere z-0">
              <div
                className="absolute top-10 lg:top-0 left-[10%]"
                style={{
                  transition: "transform 1s ",
                  transform: moveImage
                    ? "translateY(20px)"
                    : "translateY(0)",
                }}
              >
                <img src="./assets/Ellipse 429.png" alt="circle2"></img>
              </div>
              <div
                className="absolute bottom-[10%] right-[25%]"
                style={{
                  transition: "transform 2s ",
                  transform: moveImage
                    ? "translateY(45px)"
                    : "translateY(0)",
                }}
              >
                <img src="./assets/Ellipse 428.png" alt="circle2"></img>
              </div>
              <div
                className="absolute top-[10%] right-[10%]"
                style={{
                  transition: "transform 1s ",
                  transform: moveImage
                    ? "translateY(20px)"
                    : "translateY(0)",
                }}
              >
                <img src="./assets/image 100.png" alt="circle2"></img>
              </div>
            </div>
          </div>
        </div>

        <div className="Section-3 min-h-screen relative flex justify-center items-center pb-10">
          <div className="w-[90%] mx-auto md:w-[100%] md:px-10">
            <div className="w-[42%] flex justify-end h-full">
              <div
                className="hidden lg:block"
                style={{
                  transition: "transform 5s ",
                  transform: moveImage ? "translateY(35px)" : "translateY(0)",
                }}
              >
                <img src="./assets/image 100.png" alt="circle2"></img>
              </div>
            </div>
            <div>
              <p className="mt-10 lg:mt-0 text-[#FFBA00] font-museomoderno text-center font-bold text-3xl">
                D5art Passport
              </p>
            </div>
            <div className="w-[75%] mx-auto flex justify-end h-full">
              <div
                className=""
                style={{
                  transition: "transform 5s ",
                  transform: moveImage
                    ? "translateY(35px) translateX(35px)"
                    : "translateY(0) translateX(0)",
                }}
              >
                <img src="./assets/Ellipse 429.png" alt="circle2"></img>
              </div>
            </div>
            <p className="text-white mx-auto text-center w-[90%] ">
              In this realm, your D5art ID becomes your digital fingerprint, an indelible mark of your capabilities and accomplishments.
              Every milestone, every verified skill, and every achievement is etched into your D5art Passport, guiding you towards your next career leap.
            </p>
            <div className="w-[75%] mx-auto h-full">
              <div
                className="-translate-y-20"
                style={{
                  transition: "transform 5s ",
                  transform: moveImage ? "translateY(25px) " : "translateY(0) ",
                }}
              >
                <img src="./assets/Ellipse 428.png" alt="circle2"></img>
              </div>
            </div>
            <div>
              <img src="/assets/nftcv.png" className="mx-auto lg:block hidden"></img>
              <img src="/assets/skillView.png" className="border border-blue-600 p-5 rounded-lg mx-auto block lg:hidden mb-5"></img>
              <div className="flex justify-center">
                <NavLink
                  className="flex justify-center relative"
                  to="https://ex.d5art.com"
                >
                  <button className="absolute lg:-translate-y-16 text-white bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]">
                    <button className="flex pt-3 pb-1 font-play px-2.5 bg-[#1A1919] rounded-full group ">
                      <button className="p-2 rounded-full translate-y-1 group-hover:translate-x-[111px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>{" "}
                      <p className="mx-3 -translate-y-0.75 group-hover:-translate-x-4 trasnform-transition duration-500 ease-in-out text-lg">
                        Explore
                      </p>{" "}
                      <button className=" p-4 rounded-full -translate-y-1 border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                    </button>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="Section-4 min-h-screen relative flex justify-center items-center">
          <div>
            <div className="mt-20 lg:mt-0">
              <p className="text-[#FFBA00] font-museomoderno font-bold text-center text-3xl">
                Skill Realm
              </p>
            </div>
            <div className=" md:mt-10 w-[90%] mx-auto relative flex flex-col lg:flex-row justify-center">
              <div className="lg:w-[60%] px-3 lg:px-0 relative flex items-center justify-start">
                <div className="z-10">
                  <div className="z-10 flex px-5 lg:mt-10">
                    <p className="text-lg text-white lg:w-[80%] leading-9">
                      Enter the {" "}
                      <span className="text-transparent bg-clip-text font-bold bg-[#FFBA00]">
                        enchanted Skill Marketplace, {" "}
                      </span>
                      where your talents become treasures and your {" "}
                      <span className="text-transparent bg-clip-text bg-gradient-to-r font-bold from-[#FFBA00] to-[#0EA4A9]">
                        D5art Passport is your map to hidden opportunities. {" "}
                      </span>
                      In this realm, biases vanish, and only the glow of your abilities guides you forward. Recruiters see your true worth, measured not by titles or backgrounds but by the brilliance of your skills.</p>
                  </div>
                  <div className="flex justify-center lg:justify-start px-5">
                    <NavLink to="https://ex.d5art.com/SeekerRegistration">
                      <div className="flex justify-start mt-8">
                        <button className="flex pt-3 pb-1 px-2 bg-[#1A1919] z-20 rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white">
                          <button className="p-2 rounded-full translate-y-0.5 group-hover:translate-x-[119px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5"></button>
                          <p className="-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out">
                            Explore
                          </p>
                          <button className="ml-5 p-3.5 rounded-full -translate-y-1 border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                        </button>
                      </div>
                    </NavLink>
                  </div>
                </div>
                <div className="absolute z-0 bg-blue-800/15 blur-2xl w-[80%] h-[90%] rounded-full">
                </div>
              </div>
              <div className="lg:w-[30%] flex justify-center items-center font-nerko text-3xl">
                <img src="./assets/magicball.png"></img>
              </div>
            </div>
            <div className="bouceing sphere">
              <div
                className="absolute top-[15%] left-[25%]"
                style={{
                  transition: "transform 2s ",
                  transform: moveImage
                    ? "translateY(45px)"
                    : "translateY(0)",
                }}
              >
                <img src="./assets/Ellipse 428.png" alt="circle2"></img>
              </div>
              <div className="absolute top-[10%] left-[70%]" style={{ transition: "transform 0.5s ", transform: moveImage ? "translateY(25px)" : "translateY(0)", }}>
                <img src="./assets/Ellipse 429.png" alt="circle2"></img>
              </div>
              <div className="absolute top-[20%] left-[60%]" style={{ transition: "transform 1s ", transform: moveImage ? "translateY(20px)" : "translateY(0)", }}>
                <img src="./assets/image 100.png" alt="circle2"></img>
              </div>
            </div>
          </div>
        </div>

        <div className="Section-5">
          <div className="w-full md:w-[100%] lg:pt-14 px-10">
            <div className="w-[70%] flex justify-end h-full">
              <div
                className=""
                style={{
                  transition: "transform 5s ",
                  transform: moveImage
                    ? "translateY(45px) translateX(45px)"
                    : "translateY(0) translateX(0)",
                }}
              >
                <img src="./assets/image 100.png" alt="circle2" />
              </div>
            </div>
            <div>
              <p className="text-[#FFBA00] font-museomoderno font-bold text-center text-3xl">
                Skill Quest
              </p>
            </div>
          </div>
          <div className="w-full md:w-[100%] px-10 pb-10">
            <div className="flex justify-evenly h-full">
              <div
                style={{
                  transition: "transform 0.1s ",
                  transform: moveImage
                    ? "translateY(20px) translateX(20px)"
                    : "translateY(0) translateX(0)",
                }}
              >
                <img src="./assets/Ellipse 429.png" alt="circle2"></img>
              </div>
              <div className="h-full flex items-center translate-y-24">
                <img
                  style={{
                    transition: "transform 0.9s ",
                    transform: moveImage
                      ? "translateY(25px) translateX(25px)"
                      : "translateY(0) translateX(0)",
                  }}
                  className=""
                  src="./assets/Ellipse 428.png"
                  alt="circle1"
                ></img>
              </div>
            </div>
            <div className=" flex justify-center">
              <p className="text-center text-white w-[85%]">

                Embark on a transformative journey through the Skill Quest and Knowledge Journey, mastering abilities on the Wisdom Pathway and unlocking hidden wisdom to enhance your talents and expertise. on the WEB 3 Realm
                <br />

              </p>
            </div>
          </div>
          <div className="flex justify-center text-white">
            <div className="w-[90%] flex flex-col gap-5 md:gap-0 md:flex-row justify-between my-10">
              {courses.slice(0, window.innerWidth >= 1024 ? 3 : 2).map((course, index) => (
                <div
                  key={index}
                  className="lg:w-[30%] p-5 grid content-between hover:bg-black/50 duration-500 rounded-2xl"
                >
                  <div>
                    <div className="flex">
                      <img
                        src={`/assets${course.image_url}`}
                        alt="Course Image"
                      />
                    </div>
                    <p className="flex justify-between mt-3">
                      <p className="font-play text-xl font-bold">
                        {course.course_name}
                      </p>
                      <span className="text-sm mt-1">
                        <i className="fa-regular fa-calendar mr-2"></i>
                        {course.duration_hours} hours
                      </span>
                    </p>
                    <p className="flex justify-between text-xs mt-3">
                      <p>
                        <i className="fa-solid fa-list-ul mr-2 text-base"></i>
                        {course.category}
                      </p>{" "}
                      {/* <p>
                        <span className="text-[#F29D38] flex justify-center gap-1 mt-1">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                        </span>
                      </p> */}
                    </p>
                    <p className="text-sm my-3 text-gray-300">
                      {truncateDescription(course.course_description)}
                    </p>
                  </div>
                  <div className="flex justify-between text-sm">
                    <div className="flex">
                      <div>
                        <img src="./assets/Token.png" alt="Token Icon" />
                      </div>
                      <div>
                        <p className="mt-1.5 pl-2 font-bold">
                          $ {course.price}{" "}
                        </p>
                      </div>
                    </div>
                    <div>
                      <NavLink
                        to={`https://edu.d5art.com/precourse?id=${course.course_id}`}
                      >
                        <button className="bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]">
                          <button className="flex pt-2.5 pb-0.5 font-play px-2 bg-black rounded-full group" 
                           disabled={course.status === "soon" }
>
                            <button className="p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[85px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                            <p className="mx-3 -translate-y-0.5 group-hover:-translate-x-4 trasnform-transition duration-500 ease-in-out text-base">
                            
                              {course.status === "soon"
                                ? "Soon"
                                : "Enroll"}
                            </p>
                            <button className=" p-3 rounded-full -translate-y-1 border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                          </button>
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center">
            <NavLink
              className="flex justify-center relative"
              to="https://edu.d5art.com/Courses"
            >
              <button className=" text-white bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]">
                <button className="flex pt-3 pb-1 font-play px-2.5 bg-[#1A1919] rounded-full group ">
                  <button className="p-2 rounded-full translate-y-1 group-hover:translate-x-[111px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>{" "}
                  <p className="mx-3 -translate-y-0.75 group-hover:-translate-x-4 trasnform-transition duration-500 ease-in-out text-lg">
                    View All
                  </p>{" "}
                  <button className=" p-4 rounded-full -translate-y-1 border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                </button>
              </button>
            </NavLink>
          </div>
        </div>

        <div className="Section-6 py-10 mt-16 text-white relative">
          <p className="text-[#FFBA00] font-museomoderno text-center font-bold text-3xl">
            Forthcoming Wonders Await
          </p>
          <p className="text-center text-white w-[85%] mx-auto my-7">
            Anticipate the magic that lies ahead, as we embark on adventures yet unseen. The future beckons with endless possibilities!
          </p>
          <div className='w-[85%] mx-auto grid grid-cols-2 md:grid-cols-4 bg-[#D9D9D908] rounded-3xl'>
            <button className={`duration-500 h-[44px] md:h-[68px] rounded-3xl ${isActive === '0' ? 'font-bold bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00] p-[2px] ' : ''}`} onClick={() => handleTabClick('0')}><button className={`duration-500 rounded-3xl py-2 md:py-5 ${isActive === '0' ? 'bg-[#1A1919] w-full' : ''}`}>DNA Validation</button></button>
            <button className={`duration-500 h-[44px] md:h-[68px] rounded-3xl ${isActive === '1' ? 'font-bold bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00] p-[2px] ' : ''}`} onClick={() => handleTabClick('1')}><button className={`duration-500 rounded-3xl py-2 md:py-5 ${isActive === '1' ? 'bg-[#1A1919] w-full' : ''}`}>Hi Ai Validation</button></button>
            <button className={`duration-500 h-[44px] md:h-[68px] rounded-3xl ${isActive === '2' ? 'font-bold bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00] p-[2px] ' : ''}`} onClick={() => handleTabClick('2')}><button className={`duration-500 rounded-3xl py-2 md:py-5 ${isActive === '2' ? 'bg-[#1A1919] w-full' : ''}`}>Web 3 Fiefdom</button></button>
            <button className={`duration-500 h-[44px] md:h-[68px] rounded-3xl ${isActive === '3' ? 'font-bold bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00] p-[2px] ' : ''}`} onClick={() => handleTabClick('3')}><button className={`duration-500 rounded-3xl py-2 md:py-5 ${isActive === '3' ? 'bg-[#1A1919] w-full' : ''}`}>Tokenization</button></button>
          </div>
          <div className="w-[85%] mx-auto">
            {isActive === '0' && (
              <div data-aos='fade-in' data-aos-duration="1000">
                <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                  <div className="relative lg:w-[50%]">
                    <img src="/assets/DNA1.png" className="w-[80%] mx-auto z-10"></img>
                    <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#0EA4A9]/20 to-[#FFBA00]/20 blur-3xl">
                    </div>
                  </div>
                  <div className="lg:w-[50%]">
                    <p className="leading-8">Unlock the secrets of your true potential with the power of
                      <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#1CD9FF] to-[#0073FF]"> DNA-based validation. </span>
                      Harness the magic of your genetic legacy, where each strand tells a story of your innate talents and untapped possibilities. This enchanted process allows you to validate your skills, ensuring that every journey you embark upon is backed by the
                      <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#FFBA00] to-[#0EA4A9] "> authenticity of your true self.</span>
                    </p>
                    <div className="lg:h-20"></div>
                  </div>
                </div>
              </div>
            )}
            {isActive === '1' && (
              <div data-aos='fade-in' data-aos-duration="1000">
                <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                  <div className="relative lg:w-[50%]">
                    <img src="/assets/DNA2.png" className="w-[80%] mx-auto z-10"></img>
                    <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#0EA4A9]/20 to-[#FFBA00]/20 blur-3xl">
                    </div>
                  </div>
                  <div className="lg:w-[50%]">
                    <p className="leading-8">In the Fiefdom, we provide
                      <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#1CD9FF] to-[#0073FF]"> hands-on learning experiences and mentorship </span>
                      , empowering young minds to explore the limitless opportunities of the Web3 realm. Our mission is to
                      <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#FFBA00] to-[#0EA4A9] "> bridge the gap between academia and the cutting-edge skills </span>
                      required in this evolving digital landscape, unlocking a world of exciting prospects for the innovators of tomorrow.
                    </p>
                    <div className="lg:h-20"></div>
                  </div>
                </div>
              </div>
            )}
            {isActive === '2' && (
              <div data-aos='fade-in' data-aos-duration="1000">
                <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                  <div className="relative lg:w-[50%]">
                    <img src="/assets/DNA3.png" className="w-[80%] mx-auto z-10"></img>
                    <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#0EA4A9]/20 to-[#FFBA00]/20 blur-3xl">
                    </div>
                  </div>
                  <div className="lg:w-[50%]">
                    <p className="leading-8">Welcome to the Fiefdom—our dedicated
                      <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#1CD9FF] to-[#0073FF]"> Centers of Excellence (CoE) </span>
                      within educational institutions, where the next generation of Web3 pioneers is born. Here, we cultivate a
                      <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#FFBA00] to-[#0EA4A9] "> vibrant ecosystem </span>
                      that trains, guides, and supports students on their journey into the world of decentralized technology.
                    </p>
                    <div className="lg:h-20"></div>
                  </div>
                </div>
              </div>
            )}
            {isActive === '3' && (
              <div data-aos='fade-in' data-aos-duration="1000">
                <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                  <div className="relative lg:w-[50%]">
                    <img src="/assets/DNA4.png" className="w-[80%] mx-auto z-10"></img>
                    <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#0EA4A9]/20 to-[#FFBA00]/20 blur-3xl">
                    </div>
                  </div>
                  <div className="lg:w-[50%]">
                    <p className="leading-8">Imagine a world where your verified skills and achievements are represented as
                      <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#FFBA00] to-[#0EA4A9] "> unique digital tokens</span>,
                      granting you access to exclusive opportunities, resources, and rewards. With our upcoming tokenization framework, you will have the ability to showcase your expertise in a tangible form, making your professional identity more dynamic and impactful.
                    </p>
                    <div className="lg:h-20"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="w-[90%] mx-auto lg:mx-0 lg:w-[40%] lg:absolute bottom-[10%] right-[8%]">
            <p className="mt-10">Be a Early Bird</p>
            <div className="bg-gradient-to-l from-[#FFBA00] to-[#0EA4A9] p-[1px] rounded-xl lg:rounded-3xl mt-5">
              <div className="bg-[#1A1919] rounded-xl lg:rounded-3xl flex p-2">
                <input className="bg-transparent w-[75%] outline-none py-3 px-5 placeholder:text-white placeholder:italic" placeholder="Enter your mail ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                <button className="rounded-xl lg:rounded-full bg-gradient-to-r from-[#1CD9FF] to-[#0073FF] w-[25%] p-[1px]" onClick={handleJoinWaitlist}><button className="w-full h-full bg-[#1A1919] rounded-xl lg:rounded-full">Join Waitlist</button></button>
              </div>
            </div>
          </div>
        </div>

        <div className="Section-7 relative py-10 md:py-20 ">
          <div className="my-auto">
            <div className="relative ">
              <p className="text-[#FFBA00] z-10 font-museomoderno text-center font-bold text-3xl">
                Testimonials
              </p>
              <div className="z-0">
                <div
                  className="absolute -top-10 right-0 md:left-[35%]"
                  style={{
                    transition: "transform 2s ",
                    transform: moveImage ? "translateY(45px)" : "translateY(0)",
                  }}
                >
                  <img src="./assets/image 100.png" alt="circle2"></img>
                </div>
                <img
                  className="absolute top-0 left-5 md:left-20"
                  style={{
                    transition: "transform 0.9s ",
                    transform: moveImage
                      ? "translateY(25px)"
                      : "translateY(0)",
                  }}
                  src="./assets/Ellipse 428.png"
                  alt="circle1"
                ></img>
                <div
                  className="absolute top-10 right-[35%] md:right-40"
                  style={{
                    transition: "transform 0.1s ",
                    transform: moveImage ? "translateY(20px)" : "translateY(0)",
                  }}
                >
                  <img src="./assets/Ellipse 429.png" alt="circle2"></img>
                </div>
              </div>
            </div>
            <div>
              <div className="w-[85%] mx-auto text-white z-10 mt-20 hidden md:block">
                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div className="bg-[#D9D9D91F] backdrop-blur-md grid content-between rounded-[30px] p-8">
                    <p>"D5art transformed my career! The unbiased hiring process truly showcases my skills, opening doors I never imagined possible."</p>
                    <div className="flex items-center mt-4">
                      <div>
                        <img src='/assets/mas1.png' className="w-32"></img>
                      </div>
                      <div>
                        <p className="text">D5art ID: <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]">0x8511a</span></p>
                        <p className="text-sm text-nowrap">Blockchain Analyst</p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#D9D9D91F] backdrop-blur-md grid content-between rounded-[30px] p-8">
                    <p>"The D5art Passport is a game-changer! It highlights my talents and connects me with incredible opportunities in the Web3 landscape."</p>
                    <div className="flex items-center mt-4">
                      <div>
                        <img src='/assets/mas2.png' className="w-32"></img>
                      </div>
                      <div>
                        <p className="text">D5art ID: <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]">0xd2827</span></p>
                        <p className="text-sm text-nowrap">Smartcontract Developer</p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#D9D9D91F] backdrop-blur-md grid content-between rounded-[30px] p-8">
                    <p>"Thanks to D5art, I've gained confidence in my abilities. The Skill Quest helped me hone my craft and land my dream job!"</p>
                    <div className="flex items-center mt-4">
                      <div>
                        <img src='/assets/mas3.png' className="w-32"></img>
                      </div>
                      <div>
                        <p className="text">D5art ID: <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]">0x23912</span></p>
                        <p className="text-sm text-nowrap">Blockchain Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block md:hidden">
                <SliderTesty />
              </div>
            </div>
          </div>
        </div>

        <div className="Section-8  flex justify-center items-center">
          <div className=" md:h-full w-full ">
            <p className="text-[#FFBA00] font-museomoderno text-center font-bold text-3xl">
              Stay Updated
            </p>
            <div className="stay-updated ">
              <div className="px-10 md:px-28 py-10 pt-12 w-full flex flex-col lg:flex-row md:justify-between gap-3 lg:gap-7">
                <div
                  onClick={() =>
                    handleBlog(
                      "https://medium.com/@FABCLLC/how-to-deploy-a-smart-contract-on-ton-network-f99bf728934a"
                    )
                  }
                  className={`cursor-pointer group h-[10rem] lg:h-[27.5rem] flex items-end py-5 rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 0
                    ? "lg:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[18rem] lg:h-[27.5rem]"
                    : "opacity-50 lg:w-[20%] h-[2rem] lg:h-[27.5rem] border-0"
                    }`}
                  style={{
                    backgroundImage:
                      "url('./assets/1_76R-dyhg_Md21Lr83RREfQ.webp')",
                  }}
                >
                  <p
                    className={`duration-500 ${activeIndex === 0 ? "" : "hidden"
                      } bg-black bg-opacity-70 text-center text-white text-sm md:text-base py-2 md:py-5 w-full`}
                  >
                    How to Deploy a Smart contract on TON Network?
                  </p>
                </div>
                <div
                  onClick={() =>
                    handleBlog(
                      "https://medium.com/@FABCLLC/build-your-own-custom-blockchain-using-polygon-supernet-e71bd75afe70"
                    )
                  }
                  className={`cursor-pointer group h-[10rem] lg:h-[27.5rem] flex items-end py-5 rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 1
                    ? "gl:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[18rem] lg:h-[27.5rem]"
                    : "opacity-50 lg:w-[20%] h-[2rem] lg:h-[27.5rem] border-0"
                    }`}
                  style={{ backgroundImage: "url('./assets/1_23.gif')" }}
                >
                  <p
                    className={` duration-500 ${activeIndex === 1 ? "" : "hidden"
                      } bg-black bg-opacity-70 text-center text-white text-sm md:text-base py-2 md:py-5 w-full`}
                  > Build Your Own Custom Blockchain Using Polygon Supernet
                  </p>
                </div>
                <div
                  onClick={() =>
                    handleBlog(
                      "https://medium.com/@FABCLLC/polygon-the-next-generation-of-blockchain-f75bb217ace0"
                    )
                  }
                  className={`cursor-pointer group h-[10rem] lg:h-[27.5rem] flex items-end py-5 rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 2
                    ? "lg:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[18rem] lg:h-[27.5rem]"
                    : "opacity-50 lg:w-[20%] h-[2rem] lg:h-[27.5rem] border-0"
                    }`}
                  style={{
                    backgroundImage:
                      "url('./assets/1_zcczsArZudz3kD2mVfoOoQ.webp')",
                  }}
                >
                  <p
                    className={` duration-500 ${activeIndex === 2 ? "" : "hidden"
                      } bg-black bg-opacity-70 text-center text-white text-sm md:text-base py-2 md:py-5 w-full`}
                  > Polygon: The Next Generation of Blockchain.
                  </p>
                </div>
                <div
                  onClick={() =>
                    handleBlog(
                      "https://medium.com/@FABCLLC/enhancing-trust-and-transparency-polygon-id-steps-to-create-an-organization-issuer-node-f997b32b3d1b"
                    )
                  }
                  className={`cursor-pointer group h-[10rem] lg:h-[27.5rem] flex items-end py-5 rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 3
                    ? "lg:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[18rem] lg:h-[27.5rem]"
                    : "opacity-50 lg:w-[20%] h-[2rem] lg:h-[27.5rem] border-0"
                    }`}
                  style={{ backgroundImage: "url('./assets/polygon_id.gif')" }}
                >
                  <p
                    className={`duration-500 ${activeIndex === 3 ? "" : "hidden"
                      } bg-black bg-opacity-70 text-center text-white text-sm md:text-base py-2 md:py-5 w-full`}
                  > Enhancing Trust and Transparency, Polygon ID:
                  </p>
                </div>
              </div>
            </div>
            <p className="text-[#FFFFFF]/75 mx-auto text-center w-[80%] font-museomoderno">
              Keep yourself updated with insights into the latest news, trends,
              tips and best practices in recruitment, career development and
              Blockchain Technology.{" "}
            </p>
          </div>
        </div>

        <div className="Section-9">
          <div className="Council-of-SME py-10 md:py-0 lg:py-0">
            <div className="relative overflow-hidden">
              <div
                className="absolute top-10 right-[10%]"
                style={{
                  transition: "transform 1s ease-in-out",
                  transform: moveImage
                    ? "translatey(50px) "
                    : "translatey(0) translatex(50px)",
                }}
              >
                <img src="./assets/image 100.png" alt="circle2" className="rotate-45"></img>
              </div>
              <div className="flex items-center justify-center lg:h-full w-full md:pt-20 text-white ">
                <div className="flex justify-center ">
                  <div className="w-[90%] md:w-[85%]">
                    <p className="font-museomoderno text-4xl text-center text-[#FFBA00] font-bold">
                      Wizards of our{" "}
                      <span
                        style={{
                          backgroundClip: "text",
                          WebkitBackgroundClip: "text",
                          color: "transparent",
                          backgroundImage:
                            "linear-gradient(to right, #0EA4A9, #F29D38)",
                        }}
                      >
                        Dungeon
                      </span>
                    </p>
                    <p className="py-5 md:py-10 text-lg text-center font-museomoderno">
                      The Council comprises distinguished leaders and industry experts dedicated to fostering growth and innovation within our community. Each member brings a wealth of experience and expertise, playing a vital role in shaping the strategic direction of our organization.
                    </p>

                    <div class="container mx-auto hidden md:block py-10">
                      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                        <div class="text-center">
                          <img class="mx-auto rounded-lg shadow-lg w-32 h-32 lg:w-[180px] lg:h-[180px]" src="/assets/Wiz1.png" alt="Sakthi Visakan Rajaguru" />
                          <h2 class="mt-4 text-[#0EA4A9] font-semibold">Sakthi Visakan Rajaguru</h2>
                          <p class="text-white font-bold">CEO</p>
                        </div>

                        <div class="text-center">
                          <img class="mx-auto rounded-lg shadow-lg w-32 h-32 lg:w-[180px] lg:h-[180px]" src="/assets/Wiz2.png" alt="Annamalai Palaniappan" />
                          <h2 class="mt-4 text-[#0EA4A9] font-semibold">Annamalai Palaniappan</h2>
                          <p class="text-white font-bold">COO</p>
                        </div>

                        <div class="text-center">
                          <img class="mx-auto rounded-lg shadow-lg w-32 h-32 lg:w-[180px] lg:h-[180px]" src="/assets/Wiz3.png" alt="Anup Kumar" />
                          <h2 class="mt-4 text-[#0EA4A9] font-semibold">Anup Kumar</h2>
                          <p class="text-white font-bold">CTO</p>
                        </div>

                        <div class="text-center">
                          <img class="mx-auto rounded-lg shadow-lg w-32 h-32 lg:w-[180px] lg:h-[180px]" src="/assets/Wiz4.png" alt="Gaurav Sharma" />
                          <h2 class="mt-4 text-[#0EA4A9] font-semibold">Gaurav Sharma</h2>
                          <p class="text-white font-bold">CFO</p>
                        </div>
                        <div className="empty hidden lg:block"></div>
                        <div class="text-center">
                          <img class="mx-auto rounded-lg shadow-lg w-32 h-32 lg:w-[180px] lg:h-[180px]" src="/assets/Wiz5.png" alt="Khalid Al Mansouri" />
                          <h2 class="mt-4 text-[#0EA4A9] font-semibold">Khalid Al Mansouri</h2>
                          <p class="text-white font-bold">Advisory Board Member</p>
                        </div>

                        <div class="text-center">
                          <img class="mx-auto rounded-lg shadow-lg w-32 h-32 lg:w-[180px] lg:h-[180px]" src="/assets/Wiz6.png" alt="Alun Richard's" />
                          <h2 class="mt-4 text-[#0EA4A9] font-semibold">Alun Richard's</h2>
                          <p class="text-white font-bold">Advisory Board Member</p>
                        </div>
                      </div>
                    </div>

                    <div className="block md:hidden ">
                      <div className="flex justify-between mt-5">
                        <button onClick={goToPrev}>
                          <i class="fa-solid fa-chevron-left px-3 py-2 rounded-full bg-white/10"></i>
                        </button>
                        <div className="p-5 w-[70%] rounded-3xl bg-white/5 grid gap-3">
                          <div className="flex justify-center">
                            <div>
                              <img
                                src={teamMembers[currentIndex].imageSrc}
                                className=""
                                alt={teamMembers[currentIndex].name}
                              />
                            </div>
                          </div>
                          <p className="font-black text-center font-poppins text-[#0087a5] text-lg">
                            {teamMembers[currentIndex].name}
                          </p>
                          <p className="font-black text-center font-play text-lg">
                            {teamMembers[currentIndex].role}
                          </p>
                        </div>
                        <button onClick={goToNext}>
                          <i class="fa-solid fa-chevron-right px-3 py-2 rounded-full bg-white/10"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Section-10 mt-5 relative">
          <div className="">
            <div className="flex justify-center">
              <div className="w-[100%] px-10">
                <div className="">
                  <p className="text-[#FFBA00] font-museomoderno text-center font-bold text-3xl">
                    Reach us
                  </p>
                </div>
              </div>
            </div>
            <p className="py-5 text-white text-lg text-center font-museomoderno w-[80%] mx-auto">
              Join us to enhance your career with cutting-edge blockchain
              technology. Access D5art passport, exclusive courses, and industry
              insights.{" "}
            </p>
            <div className="pb-20 pt-10 w-[90%] mx-auto flex flex-col md:flex-row justify-center gap-5">
              <div
                className="md:w-[60%] bg-[#D9D9D90F] text-gray-300 rounded-2xl p-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col md:flex-row justify-center gap-10">
                    <div className="md:w-[50%] flex flex-col gap-10">
                      <div className="flex flex-col">
                        <label className="text-sm 2xl:text-lg">First Name</label>
                        <input
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleSubmitChange}
                          className="bg-transparent border-b outline-none w-[100%]"
                          required
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-sm 2xl:text-lg">Email</label>
                        <input
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleSubmitChange}
                          className="bg-transparent border-b outline-none w-[100%]"
                          required
                        />
                      </div>
                    </div>
                    <div className="md:w-[50%] flex flex-col gap-10">
                      <div className="flex flex-col">
                        <label className="text-sm 2xl:text-lg">Last Name</label>
                        <input
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleSubmitChange}
                          className="bg-transparent border-b outline-none w-[100%]"
                          required
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-sm 2xl:text-lg">Phone</label>
                        <input
                          name="phone"
                          value={formData.phone}
                          onChange={handleSubmitChange}
                          className="bg-transparent border-b outline-none w-[100%]"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mt-10">
                    <label className="text-sm 2xl:text-lg">Message</label>
                    <input
                      name="message"
                      value={formData.message}
                      onChange={handleSubmitChange}
                      className="bg-transparent placeholder:text-sm py-1 border-b outline-none w-[100%]"
                      placeholder="Write your message..."
                      required
                    />
                  </div>
                  <div className="mt-10 md:pt-20 pb-5 flex justify-center">
                    <button
                      type="submit"
                      className="text-white bg-gradient-to-r from-[#0E2DA7] to-[#0EA4A9] rounded-full p-[2px]"
                    >
                      <div className="flex pt-3 pb-1 font-play px-2.5 bg-[#09090B] rounded-full group">
                        <div className=" w-5 h-5 rounded-full translate-y-1 group-hover:translate-x-[110px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></div>
                        <p className="mx-3 -translate-y-0.75 group-hover:-translate-x-4 transform-transition duration-500 ease-in-out text-lg">
                          Submit
                        </p>
                        <div className="p-4 rounded-full -translate-y-1 border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></div>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
              <div className="md:w-[40%]">
                <div>
                  <img src="/assets/MAILBOX.png"></img>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className=" absolute -top-10 right-[25%]"
              style={{
                transition: "transform 2s ",
                transform: moveImage ? "translateY(45px)" : "translateY(0)",
              }}
            >
              <img src="./assets/image 100.png" alt="circle2"></img>
            </div>
            <div className="absolute bottom-0 right-[5%] ">
              <img
                style={{
                  transition: "transform 0.9s ",
                  transform: moveImage
                    ? "translateY(25px)"
                    : "translateY(0)",
                }}
                className=""
                src="./assets/Ellipse 428.png"
                alt="circle1"
              ></img>
            </div>

            <div className="absolute -top-10 left-[10%]"
              style={{
                transition: "transform 0.9s ",
                transform: moveImage
                  ? "translateY(20px) translateX(20px)"
                  : "translateY(0) translateX(0px)",
              }}
            >
              <img src="./assets/Ellipse 429.png" alt="circle2"></img>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

