import React from 'react'
import 'animate.css';
import { useState, useEffect, useRef } from 'react';


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Select from 'react-select';
import { NavLink, useNavigate } from 'react-router-dom';
import Spinners from '../Loaders/Spinners';
import Loading from '../Loaders/Loading';
import createGuest from 'cross-domain-storage/guest';
import createHost from "cross-domain-storage/host";

// const storageHost = createHost([

// {
//     origin: "https://edu.d5art.com", 
//     allowedMethods: ["get", "set", "remove"],
// },
// {
//   origin: "https://staging.d5art.com", 
//   allowedMethods: ["get", "set", "remove"],
// },
// {
//   origin: "https://ex.d5art.com", 
//   allowedMethods: ["get", "set", "remove"],
// },
// ]);

export default function Header() {
  const [isDropdown, setDropdown] = useState(false);
  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

 
  const [isTransformed, setIsTransformed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const toggleModal = () => {
    // If modal is open, show loading
    if (!isOpen) {
      setIsLoading1(true);
      setTimeout(() => {
        setIsLoading1(false);
        setIsOpen(!isOpen); 
      }, 1000); 
    } else {
      setIsOpen(!isOpen); 
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsTransformed(true);
    } else {

      setIsTransformed(false);
    }
  }, [isOpen]);

  //const dispatch = useDispatch();

  // const Logout = () => {
  //   dispatch(setUserEmail(""));
  //   window.location.reload();
  // };

  const [ExpandSkill, setExpandSkill] = useState(false)
  const [ExpandCourse, setExpandCourse] = useState(false)

  const handleExpandSkill = () => {
    setExpandSkill(!ExpandSkill);
  };

  const handleExpandCourse = () => {
    setExpandCourse(!ExpandCourse);
  };



  // Only open the hovered button's dropdown
  const handleMouseEnterCourse = () => {
    setExpandCourse(true);
  };

  const handleMouseLeaveCourse = () => {
    setExpandCourse(false);
  };

  const handleMouseEnterSkill = () => {
    setExpandSkill(true);
  };

  const handleMouseLeaveSkill = () => {
    setExpandSkill(false);
  };

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdown]);


  useEffect(() => {
   
    const bazStorage = createGuest("https://ex.d5art.com");

    bazStorage.get("edu_email", function (error, value) {
      if (error) {
      //  console.error("Error retrieving edu_email from cross-domain storage:", error);
      } else {
        if (value) {
          console.log("role",value)
          
          localStorage.setItem("edu_email",value );
          // dispatch(setSeekerEmail(value));
          // dispatch(setUserRole("seeker"));

        }
    }
    });


  
  }, [])

 

  const seeker_email = localStorage.getItem("edu_email");
  const isAuthenticated = seeker_email !== null;





  const Logout = async () => {

  const storageGuest1 = createGuest('https://edu.d5art.com');
  const storageGuest2 = createGuest('https://ex.d5art.com');

 
    localStorage.removeItem('edu_email'); 

    storageGuest1.remove('persist:root', function (error) {
      if (error) console.error('Error removing email from https://edu.d5art.com:', error);
      else console.log('Email removed successfully from https://edu.d5art.com');
  });

  storageGuest2.remove('persist:root', function (error) {
      if (error) console.error('Error removing email from https://ex.d5art.com:', error);
      else console.log('Email removed successfully from https://ex.d5art.com');
  });

     
    window.location.reload();
  };

  const handleUserClick = () => {
    setIsOpen(!isOpen);
  };
  



  return (
    <div>
      <nav className='flex p-7  lg:px-10  font-poppins'>
        <div className='w-[85%] lg:w-[15%]'>
          <div className='w-[80%]'>
            <a href='/'> <img src='/assets/D5ART_T White_PNG_new.png' className='h-14'></img> </a>
          </div>
        </div>
        <div className='w-[15%] lg:w-[85%] hidden lg:block'
        >
          <div className='flex ' >
            <div className='header_content w-[80%] relative text-gray-300 font-bold flex justify-center lg:gap-14 mt-3'>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
                href='/'
              >Home</a>
              <div className='relative' onMouseLeave={() => { handleMouseLeaveCourse() }}>
                <a href='https://edu.d5art.com/'>
                  <button

                    className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
                    onClick={() => { handleExpandCourse(); }}
                    onMouseEnter={handleMouseEnterCourse}
                  >
                    Courses
                    <span className='ml-2'>&#9660;</span> {/* Unicode down arrow */}
                  </button>
                </a>
                <div
                  className={`absolute z-40 -left-[45%] mt-2 rounded-md bg-black/75 backdrop-blur-sm w-[10rem] transition-all duration-700 overflow-hidden
                    ${ExpandCourse ? 'max-h-[300px] border border-white/30' : 'max-h-0'}`}
                >
                  <ul className="p-5 text-sm text-center flex flex-col gap-2 divide-transparent">
                    <a href='https://edu.d5art.com/Courses' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Explore Courses</a>
                    <a href='https://edu.d5art.com/dashboard' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Learning Dashboard</a>
                    <a href='https://edu.d5art.com/dashboard?tab=certificate' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Certificate</a>
                    <a href='https://edu.d5art.com/dashboard?tab=wallet' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Wallet</a>
                    <a href='https://edu.d5art.com/dashboard?tab=result' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Assessment</a>
                  </ul>
                </div>
              </div>

              <div className='relative' onMouseLeave={() => { handleMouseLeaveSkill() }}>
                <a href='https://ex.d5art.com'>
                  <button

                    className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
                    onClick={handleExpandSkill}
                    onMouseEnter={handleMouseEnterSkill}
                  >
                    Skill Exchange
                    <span className='ml-2'>&#9660;</span> 
                  </button>
                </a>
                <div
                  className={`absolute z-40 -left-4 mt-2 rounded-md bg-black/75 backdrop-blur-sm w-[10rem] transition-all duration-700 overflow-hidden 
                    ${ExpandSkill ? 'max-h-[300px] border border-white/30' : 'max-h-0'}`}
                >
                  <ul className="p-5 text-sm text-center flex flex-col gap-2 divide-transparent">
                    {/* <a href='/SeekerRegistration' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>My NFT</a>
                    <a href='/SeekerRegistration' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>My Offers</a> */}
                    <a href='https://ex.d5art.com/SeekerRegistration' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Dashboard</a>
                    <a href='https://ex.d5art.com/SeekerRegistration' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Wallet</a>
                  </ul>
                </div>
              </div>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='https://edu.d5art.com/Events' >Events</a>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/faq'>FAQ</a>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/dna-home'>DNA</a>

            </div>
            <div className='w-[20%] flex justify-center items-center'>
            {isAuthenticated ? (
                <div className="gap-2 flex ">
                  <div
                    className="flex gap-2 rounded-md bg- text-white  px-3 py-2 cursor-pointer"
                    onClick={handleUserClick}
                  >
                    <button>
                      <button className="p-3 rounded-full border border-sky-500 mr-1"></button>
                      <div className="absolute -translate-y-10 -translate-x-2">
                        <img className="h-[3rem]" src="/assets/image 90.png"></img>
                      </div>
                    </button>
                    <p className="user-username">
                      {seeker_email.substr(0, 10) + "...."}
                    </p>
                    <i class="fa-solid fa-chevron-down pl-2 pt-1 text-xs"></i>
                  </div>
                  {isOpen && (
                    <div className="absolute z-10 mt-10">
                      <div className="rounded-lg w-[10rem]  text-white text-center mt-2 p-2 shadow-lg ring-1 ring-white ring-opacity-30 focus:outline-none animate__animated animate__fadeInUp bg-black">
                        {/* <button className="text-black py-1">
                          <a href='/ProfileEdit'> <i class="fa-solid fa-user-pen"></i>
                            &nbsp;Profile Edit</a>
                        </button>
                        <button className="text-black py-1">
                          <a href='/Result' className='flex'><i class="fa-solid fa-square-poll-vertical w-[10%]"></i>
                            &nbsp;<p className='w-[90%]'>Result</p></a>
                        </button>*/}
                        {/* <a href='https://rms.fabc.global/RMS/Seeker/SeekerDashboard' className='flex justify-center py-1'> <i class="fa-solid fa-chart-line pt-1 pr-2"></i>
                          <p className=''>Carrier path </p></a> */}
                        <a
                          href="/Dashboard/dashboard"
                          className="flex justify-center py-1"
                        >
                          {" "}
                          <i class="fa-solid fa-book-open-reader pt-1 pr-2"></i>
                          <p className="">Dashboard </p>
                        </a>
                        <button onClick={Logout}>
                          <i className="fa-solid fa-right-from-bracket  fa-rotate-180 py-1"></i>
                          &nbsp;&nbsp;Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex gap-5   ">
                  {/* <button
                    onClick={() => navigate("/Login")}
                    className="login-button text-FABcYellow flex mt-2"
                  >
                    <i className="fa-solid fa-lock mr-2 mt-1"></i>Login
                  </button>
                  <div className="relative inline-block text-left">
                    <NavLink to="/Register">
                      <button
                        type="button"
                        className="  rounded-md bg-FABcYellow px-4 py-2  text-black shadow-sm "
                        id="menu-button"
                        aria-haspopup="true"
                        onClick={toggleDropdownLogin}
                      >
                        <i className="fa-solid fa-user mr-2 mt-1" />
                        Signup
                      </button>
                    </NavLink>

                  </div> */}

                  <NavLink to="https://ex.d5art.com/SeekerRegistration">
                    <button className="flex pt-3 pb-1 px-3 rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white">
                      <button className="p-2 rounded-full translate-y-0.5 group-hover:translate-x-[112px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5"></button>{" "}
                      <p className="mt-0.5 -translate-y-1 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out">
                        Login
                      </p>{" "}
                      <button className="ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]"></button>
                    </button>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='w-[15%] table lg:hidden grid content-center'>
          <div className='flex justify-center '>
            <button onClick={handleDropdown} ref={buttonRef}>
              <i class="fa-solid fa-ellipsis-vertical text-white text-2xl "></i>
            </button>
            {isDropdown && (
              <div className='dropdown-content text-black z-10 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp' ref={dropdownRef}>
                <div className='flex flex-col gap-3 bg-black text-white rounded-lg p-5'>
                  <a href='/' className='text-center'>Home</a>
                  <a href='https://edu.d5art.com/' className='text-center'>Courses</a>
                  <a href='/SkillArena' className='text-center'>Skill Exchange</a>
                  <a href='https://edu.d5art.com/Events' className='text-center'>Events</a>
                  <a href='/dna-home' className='text-center'>DNA</a>
                  <a href='/faq' className='text-center'>FAQ</a>

                  <div className='flex justify-center'>
                  <NavLink to="https://ex.d5art.com/SeekerRegistration">
                    <button className='flex py-1 pt-2 px-3 bg-[#101010] rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={toggleModal}>
                      <button className='p-1 rounded-full translate-y-1.5 group-hover:translate-x-[80px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] mr-2'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out'>Login</p> <button className='ml-2 p-2.5 rounded-full translate-y-0  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                    </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading1 && (
          <div className=''>
            <div className='z-100'>
              <Loading isVisible1={isLoading1} />
            </div>
          </div>
        )}
      

      </nav>
      <style>
        {`
        @media (min-width: 999px) and (max-width: 1033px) {
          .header_content {
            display: flex;
            gap: 40px;
          }
        }
      `}
      </style>
    </div>
  )
}