

import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { toast, Toaster } from "react-hot-toast";


export default function Footer() {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      sendEmail();
      toast.success('Your email sent successfully');
      setEmail('');
    } else {
      toast.error('Please enter a valid email');
    }
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendEmail = () => {
    console.log('Email sent to: ', email);
  };

  return (
    <div className='font-bricolagegrotesque '>
      <Toaster />
      <footer class="text-[#71717A] leading-loose bg-[#101010] pt-3">

        <div
          class="grid md:grid-cols-2 lg:flex items-start justify-between text-[14px] 2xl:text-base gap-3  w-[90%] mx-auto py-5  text-center lg:text-left"
        >
          <div class="sec-1  lg:w-[20%]">
            <div className='flex lg:justify-start justify-center'>
              <NavLink to="/"><img
                src='/assets/D5ART_T White_PNG_new.png'
                alt="logo"
                className="w-12 md:w-20 lg:w-32 mb-3"
              /></NavLink>
            </div>
            <h2 class="font-bold text-2xl ">Contact Us</h2>
            <p> <a href='tel:+971509718682'>+971 50 971 8682</a> </p>
            <p>  <a href='tel:+919566299429'>+91 95662 99429 </a></p>
            {/* <p>Contact us today for your free discovery call with one of ourleading experts</p> */}
            <h4 class="font-semibold"><a href='mailto:support@fabc.global'>Email:  support@fabc.global  </a></h4>

          </div>
          <div class="sec-3  hidden md:block lg:hidden lg:w-[10%] mt-5 md:mt-0">
            <h2 class="font-bold  text-2xl mb-3">Explore</h2>
            <NavLink to="/" ><p>Home</p></NavLink>
            <NavLink to="/About"><p>About</p></NavLink>
            <NavLink to="/Courses"><p>Course</p></NavLink>
            <NavLink to="/Events"><p>Events</p></NavLink>
            <NavLink to="/FAQ"><p>FAQ</p></NavLink>
          </div>
          <div class="sec-2  lg:w-[25%] mt-5 md:mt-0 ">
            <h2 class="font-bold  text-2xl">Dubai Presence</h2>
            <p> FABC COMPUTER SYSTEMS LLC PLOT 301-5, 32D STREET  AL FATTAN DOWNTOWN, Al SATWA, DUBAI, UAE PB-142507</p>
            {/* <p>Aspin Commercial Towers, 17th Floor 1701-04, Sheik Zayed Road, Dubai.</p> */}
            <div class="flex items-center justify-center gap-3 text-xl mt-3 lg:items-start lg:justify-start">
              <a href='https://www.facebook.com/share/kShGUtebskjqPtzP/?mibextid=qi2Omg' target="_blank">
                <button class="">
                  <i class="fa-brands fa-facebook-f fa-sm" ></i>
                </button></a>

              <a href='https://www.linkedin.com/company/fabc-llc/' target="_blank">
                <button>
                  <i class="fa-brands fa-linkedin-in"  ></i>
                </button></a>

              <a href='https://www.instagram.com/fabc.global/' target="_blank">
                <button>
                  <i class="fa-brands fa-instagram "></i>
                </button></a>
              <a href="https://t.me/fabc_global" target='_blank'><i class="fa-brands fa-telegram"></i></a>
              <a href="https://chat.whatsapp.com/GPS7J1QETwg3ocnjKofPT8 " target='_blank'><i class="fa-brands fa-whatsapp"></i></a>
              <a href="https://medium.com/@FABCLLC/" target='_blank'><i class="fa-brands fa-medium"></i></a>
              <a href=" https://x.com/FabcGlobal" target="_blank" rel="noopener noreferrer">
                <button>
                  <i className="fa-brands fa-twitter "></i>
                </button>
              </a>
              <a href="https://www.threads.net/" target="_blank" rel="noopener noreferrer">  <button>
                <i className="fa-brands fa-thread "></i>
              </button>
              </a>
            </div>
          </div>
          <div class="sec-3  hidden lg:block lg:w-[10%] mt-5 md:mt-0">
            <h2 class="font-bold  text-2xl mb-3">Explore</h2>
            <NavLink to="/" ><p>Home</p></NavLink>
            <NavLink to="https://ex.d5art.com/"><p>Skill Exchange</p></NavLink> 
            <NavLink to="https://edu.d5art.com/"><p>Course</p></NavLink>
            <NavLink to="https://edu.d5art.com/Events"><p>Events</p></NavLink>
            <NavLink to="/FAQ"><p>FAQ</p></NavLink>
          </div>
          <div className="sec-4 hidden md:block lg:w-[25%] mt-5 md:mt-0">
            <h4 className="font-bold  text-2xl ">Subscribe</h4>
            <p>
              Subscribe now to receive the latest news and updates directly to your inbox!
            </p>
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <input
                type="email"
                className="p-1 outline-none bg-transparent border border-[#71717A] rounded-lg my-4 px-4"
                placeholder="Email here"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="submit"
                className="bg-[#0087A5] text-white px-5 py-1 rounded-lg hover:bg-[#FFBA00] hover:text-black duration-500"
              >
                Subscribe Now
              </button>
            </form>
          </div>
        </div>
        <div class="bg-FABCLightBlue flex flex-col md:flex-row items-center justify-between py-2 px-8 bg-[#101010] border-y border-[#71717A] text-sm">
          <p>Privacy Policy | Terms & Conditions</p>
          <p>
            COPYRIGHT
            <i class="fa-regular fa-copyright" style={{ color: "#333333" }}></i> 2024
            FABCLABS
          </p>
        </div>
      </footer>
    </div>
  )
}