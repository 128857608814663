import React, { useRef, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Select from 'react-select';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import Aos from 'aos';
import 'aos/dist/aos.css';
import toast, { Toaster } from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import RecaptchaComponent from '../Recaptcha';


Aos.init();

export default function DnaHome() {
    const [isactive, setactive] = useState('wealth');
    const [Purpose, setPurpose] = useState(null);
    const [ConfirmSubmission, setConfirmSubmission] = useState(false);
    const [ConfirmKit, setConfirmKit] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const recaptchaRef = useRef(null);
    const reCaptcha_public = "6LeDPCoqAAAAAH7ABnqmmz3q7YvSZIXZxbRE4mN2"
    const [exchangeRates, setExchangeRates] = useState({});
    const [selectedCurrency, setSelectedCurrency] = useState({value: 'USD', label: 'USD'});
    const [convertedPrice, setConvertedPrice] = useState(210); 
    const basePrice = 210;

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobile: '',
        address: '',
        country: '',
        zipCode: '',
        referCode: '',
        areYouHereAs: "",
      });



    const handleActivetab = (tab) => {
        setactive(tab)
    }

    const Selectpurpose = [
        { value: 'CXO', label: 'CXO' },
        { value: 'Student', label: 'Student' },
        { value: 'Graduate', label: 'Graduate' },
        { value: 'Working professional', label: 'Working professional' },
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '0px',
            borderBottom: '1px dashed',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
            outline: 'none !important',
            ring: 'none',
            borderRadius: '0px',
            '&:hover': {                        
                borderBottom: '1px dashed white',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'lightgray',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: state.isSelected ? '#08f' : '#000',
            color: '#fff',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        input: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#000',
        }),
    };

    const handlePurpose = (selectedOption) => {
        setPurpose(selectedOption);

        setFormData((prevData) => ({
            ...prevData,
            areYouHereAs: selectedOption.value, 
        }));
    };

    const exploreSectionRef = useRef(null);
    const scrollToExploreSection = () => {
        exploreSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSubmission = () => {
       
        const { fullName, email, mobile, address, country, zipCode, areYouHereAs } = formData;

        console.log("formData",formData)

        if (!fullName || !email || !mobile || !address || !country || !zipCode || !areYouHereAs) {
            toast.error('Please fill out all required fields');
            return;
        }

          if (!recaptchaToken) {
            toast.error('Please complete the reCAPTCHA');
            return;
          }
        setConfirmSubmission(!ConfirmSubmission)
    }

    const handleKit = () => {
        setConfirmKit(!ConfirmKit)
       // handleBookKit()
    }

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    useEffect(() => {
        axios
            .get('https://api.exchangerate-api.com/v4/latest/USD')
            .then((response) => {
                setExchangeRates(response.data.rates);
            })
            .catch((error) => console.error('Error fetching exchange rates:', error));
    }, []);

    const handleCurrencyChange = (selectedOption) => {
        setSelectedCurrency(selectedOption);
        const rate = exchangeRates[selectedOption.value];
        if (rate) {
            setConvertedPrice((basePrice * rate).toFixed(2));
        }
    };

    const currencyOptions = Object.keys(exchangeRates).map((currency) => ({
        value: currency,
        label: currency,
    }));



    console.log('selectedCurrency',selectedCurrency)
    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleBookKit = async () => {
        const loadingToastId = toast.loading("Processing your booking...");

        try {
          const response = await axios.post('https://edu.d5art.com/api/book-dna-kit', {recaptchaToken,formData,convertedPrice,selectedCurrency});
          const { orderId, trackingId } = response.data;
    
          console.log('response.data',response.data)
    
          const options = {
            key: "rzp_test_YrzYxp9mOwo6N9", 
            amount: convertedPrice,
            currency: selectedCurrency.value,
            name: "DNA Kit Booking",
            description: "Complete your payment to confirm booking.",
            order_id: orderId,
            handler: async (response) => {
              const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
              try {
              await axios.post('https://edu.d5art.com/api/verify-dna-payment', {
                orderId: razorpay_order_id,
                paymentId: razorpay_payment_id,
                signature: razorpay_signature,
                amount: convertedPrice,
                currency: selectedCurrency.value,
                trackingId,
                formData,
              });
              toast.dismiss(loadingToastId);
              toast.success("Payment successful and booking confirmed!", { id: loadingToastId });
              setConfirmKit(!ConfirmKit)
            } catch (verifyError) {
                console.log("verifyError",verifyError)
                toast.dismiss();
                toast.error("Payment verification failed. Please contact support.");
              }
            },
            prefill: {
              name: formData.fullName,
              email: formData.email,
              contact: formData.mobile,
            },
            modal: {
                ondismiss: () => {
                  toast.dismiss();
                  toast.error("Payment was canceled. Please try again.");
                //   expireRecaptchaToken()
                //   handleRecaptchaChange()
                handleTokenExpiration()
                  setConfirmSubmission(!ConfirmSubmission)
                  console.log("User exited the payment flow without completing payment.");
                }
              }
          };
    
          const rzp = new window.Razorpay(options);
          rzp.open();
        } 
        catch (error) {
            toast.dismiss();
            // expireRecaptchaToken()
            // handleRecaptchaChange()
            handleTokenExpiration()
            setConfirmSubmission(!ConfirmSubmission)
            if (error.response) {
                if (error.response.status === 400 && error.response.data.message === "User with this email or mobile already exists") {
                  toast.error("User with this email or mobile already exists. Please try again with different details.");
                } else if (error.response.data === "ReCaptcha failed") {
                  toast.error("ReCaptcha validation failed, please try again.");
                } else {
                  toast.error("Error occurred during booking. Please try again.");
                }
              } else {
                toast.error("Unexpected error occurred. Please try again.");
              }



          }
      };

    //   const expireRecaptchaToken = () => {
    //     if (recaptchaRef.current) {
    //       recaptchaRef.current.reset(); 
    //       setRecaptchaToken(null); 
    //       console.log("reCAPTCHA token expired")
    //     //   toast.error("reCAPTCHA token expired");
    //     }
    //   };
    
    const handleTokenExpiration = () => {
        console.log("Token expired on Login page");
        setRecaptchaToken(null);
        if (recaptchaRef.current) recaptchaRef.current.resetRecaptcha(); // Reset reCAPTCHA explicitly
      };
      


    return (
        <div className='text-white font-poppins overflow-hidden'>
            <div className='bg-[#1A1919]'>
            <Toaster />
                <Header />
                <div className='sec-1 text-lg 2xl:text-xl flex items-center justify-center text-center relative' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                    <div className='z-20'>
                        <div className='flex justify-center'>
                            <h1 className='text-2xl md:text-3xl 2xl:text-4xl font-bold text-transparent bg-gradient-to-r from-[#31989B] to-[#4A5CA4] bg-clip-text '
                            >Let’s Nurture your Nature</h1>
                        </div>
                        <p className='italic mt-7 w-[90%] md:w-[60%] mx-auto'>“DNA is the script of life, and with each enhancement, we evolve the story—turning potential into power, and existence into evolution”</p>
                        <img data-aos='flip-left' src='/assets/DNAhero.png' className='w-[90%] md:w-[25%] 2xl:w-[35%] mt-5 mx-auto'></img>
                        <div className='flex flex-col md:flex-row justify-center gap-5 md:gap-7 mt-10'>
                            <button onClick={scrollToExploreSection} className='mx-auto md:mx-0 font-play bg-[#d9d9d9]/35 backdrop-blur-md py-2 px-7 rounded-full'>
                                Book my kit
                            </button>
                        </div>
                    </div>
                    <div className='absolute flex justify-between z-10 w-[100%]'>
                        <img data-aos='flip-up' data-aos-duration='1000' src='/assets/Heroleft.png' className='w-[20%]'></img>
                        <img data-aos='flip-up' data-aos-duration='1000' src='/assets/Heroright.png' className='w-[20%]'></img>
                    </div>
                    <div className='absolute flex justify-around z-0'>
                        <div className='bg-[#00FFC2]/5 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
                        <div className='bg-[#7B00FF]/10 blur-3xl w-[20rem] h-[20rem] rounded-full'></div>
                        <div className='bg-[#4B53FC]/15 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
                    </div>
                    <div>
                        <img src='/assets/C1.png' className='absolute top-10 md:top-0 left-[20%] w-5 md:w-12 h-5 md:h-12 fa-bounce'></img>
                    </div>
                </div>
                <div className='sec-2 py-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                    <div className='w-[90%] mx-auto'>
                        <div className='flex justify-start'>
                            <h1 className='bg-gradient-to-r from-[#31989B] to-[#F29D38] bg-clip-text text-transparent text-xl md:text-3xl font-bold'>What awaits you</h1>
                        </div>
                        <div className='flex justify-center my-7'>
                            <div className='border border-[#0E2DA7] rounded-full'>
                                <button className={`border-2  font-bold px-5 py-1 rounded-full duration-500 ${isactive === 'wealth' ? 'border-[#F29D38]' : 'border-transparent'}`} onClick={() => { handleActivetab('wealth') }}><p className={` bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] bg-clip-text duration-500 ${isactive === 'wealth' ? 'text-transparent' : ''}`} >Wealth</p></button>
                                <button className={`border-2  font-bold px-5 py-1 rounded-full duration-500 ${isactive === 'health' ? 'border-[#F29D38]' : 'border-transparent'}`} onClick={() => { handleActivetab('health') }}><p className={` bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] bg-clip-text duration-500 ${isactive === 'health' ? 'text-transparent' : ''}`} >Health</p></button>
                            </div>
                        </div>
                        {isactive === 'wealth' && (
                            <div data-aos='fade-in' data-aos-duration='1000'>
                                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-10 pb-10'>
                                    <div data-aos='fade-up' data-aos-duration='1000' className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Personalized Skill Development</h1>
                                            <img src='/assets/W1.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Suggests optimal learning methods based on cognitive and behavioral genetic traits, enhancing upskilling efforts.</p>
                                        </div>
                                    </div>
                                    <div data-aos='fade-up' data-aos-duration='1250' className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Tailored Learning</h1>
                                            <img src='/assets/W2.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Aligns genetic predispositions with suitable professions or industries for greater job satisfaction and success.</p>
                                        </div>
                                    </div>
                                    <div data-aos='fade-up' data-aos-duration='1500' className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Resilience and adaptability</h1>
                                            <img src='/assets/W3.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Assesses traits linked to adaptability and stress management, crucial for thriving in dynamic work environments.</p>
                                        </div>
                                    </div>
                                    <div data-aos='fade-up' data-aos-duration='1750' className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Entrepreneurial Potential</h1>
                                            <img src='/assets/W4.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Helps individuals understand work styles or focus patterns to boost efficiency and performance.</p>
                                        </div>
                                    </div>
                                    <div data-aos='fade-up' data-aos-duration='2000' className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Leadership Potential</h1>
                                            <img src='/assets/W5.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'> Identifies traits such as decision-making, emotional intelligence, and risk management, helping individuals pursue leadership roles.</p>
                                        </div>
                                    </div>
                                    <div data-aos='fade-up' data-aos-duration='2250' className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Personalized Skill Development</h1>
                                            <img src='/assets/W6.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Identifies natural aptitudes and talents, allowing individuals to focus on strengths for career growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isactive === 'health' && (
                            <div data-aos='fade-in' data-aos-duration='1000'>
                                <div className='flex flex-wrap justify-center gap-5 lg:gap-10 pb-10'>
                                    <div data-aos='fade-up' data-aos-duration='1000' className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Personalized Medicine</h1>
                                            <img src='/assets/H1.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Tailors treatments and medications to individual genetic profiles for more effective outcomes.</p>
                                        </div>
                                    </div>
                                    <div data-aos='fade-up' data-aos-duration='1250' className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Diet and Nutrition Optimization</h1>
                                            <img src='/assets/H2.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Guides personalized dietary plans based on genetic responses to nutrients.</p>
                                        </div>
                                    </div>
                                    <div data-aos='fade-up' data-aos-duration='1500' className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Drug Response</h1>
                                            <img src='/assets/H3.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'> Helps determine how individuals metabolize certain medications, reducing adverse effects.</p>
                                        </div>
                                    </div>
                                    <div data-aos='fade-up' data-aos-duration='1750' className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Preventive Care</h1>
                                            <img src='/assets/H4.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Enables early interventions and lifestyle adjustments to prevent potential health issues.</p>
                                        </div>
                                    </div>
                                    <div data-aos='fade-up' data-aos-duration='2000' className='w-[100%] md:w-[46%] lg:w-[30%] bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] rounded-xl p-[2px]'>
                                        <div className='bg-[#1A1919] rounded-xl px-5 py-7 h-full'>
                                            <h1 className='text-center text-lg'>Disease Risk Prediction</h1>
                                            <img src='/assets/H5.png' className='mx-auto my-7'></img>
                                            <p className='text-sm'>Identifies genetic predispositions to conditions like cancer, heart disease, or diabetes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='sec-3 pb-10 2xl:pt-10 flex items-center' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                    <div className='w-[90%] mx-auto'>
                        <div className='flex justify-start'>
                            <h1 className='bg-gradient-to-r from-[#31989B] to-[#F29D38] bg-clip-text text-transparent text-xl md:text-3xl font-bold'>Be an Early Adapter</h1>
                        </div>
                        <div className='flex justify-center items-center my-7 font-semibold text-center md:text-start'>
                            <p className=' bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] text-transparent bg-clip-text duration-500'>Book the kit</p>
                            <hr className='w-20 h-[1px] border-dashed mx-1'></hr>
                            <p>Generate Report</p>
                            <hr className='w-20 h-[1px] border-dashed mx-1'></hr>
                            <p>Assess & Enhance</p>
                        </div>
                        <div className='flex flex-col lg:flex-row items-center gap-5 lg:gap-0'>
                            <div className='w-[100%] lg:w-[50%] text-center'>
                                <img data-aos='flip-left' data-aos-duration='1000' src='/assets/DNAform.png' className='w-[80%] 2xl:w-[60%] mx-auto'></img>
                                <p className='text-lg italic'>“Your Data Your Control”</p>
                                <p className='text-sm 2xl:w-[60%] mx-auto'>The Data test report is stored on Blockchain where the user only have the access to their own data</p>
                            </div>
                            <div className='w-[100%] lg:w-[50%]' ref={exploreSectionRef}>
                                <div class="w-[100%] md:w-[90%] 2xl:w-[80%] bg-[#232323] mx-auto relative p-5 md:p-10 rounded-2xl shadow-md">
                                    <h1 className='text-center text-[#F29D38] text-lg font-semibold'>Book your DNA test kit</h1>
                                    <div className='grid gap-5'>
                                        <div>
                                            <label className='text-sm'>Full Name :</label>
                                            <input
                                             type="text"
                                             name="fullName"
                                             value={formData.fullName}
                                             onChange={handleChange}
                                             className='border-b border-dashed outline-none w-[100%] bg-[#232323]'
                                            
                                            ></input>Quaerat eum facilis 
                                        </div>
                                        <div className='flex flex-col md:flex-row gap-5'>
                                            <div>
                                                <label className='text-sm'>Mail ID :</label>
                                                <input
                                                 type='email' 
                                                 className='border-b border-dashed outline-none w-[100%] bg-[#232323]'
                                                 name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                 ></input>
                                            </div>
                                            <div>
                                                <label className='text-sm'>Mobile :</label>
                                                <input
                                                   type="number"
                                                   name="mobile"
                                                   value={formData.mobile}
                                                   onChange={handleChange}
                                                className='border-b border-dashed outline-none w-[100%] bg-[#232323]'
                                                ></input>
                                            </div>
                                        </div>
                                        <div>
                                            <label className='text-sm'>Address :</label>
                                            <input 
                                             type="text"
                                             name="address"
                                             value={formData.address}
                                             onChange={handleChange}
                                            className='border-b border-dashed outline-none w-[100%] bg-[#232323]'
                                            ></input>
                                        </div>
                                        <div>
                                            <label className='text-sm'>Country :</label>
                                            <input 
                                             type="text"
                                             name="country"
                                             value={formData.country}
                                             onChange={handleChange}
                                            className='border-b border-dashed outline-none w-[100%] bg-[#232323]'
                                            ></input>
                                        </div>
                                        <div>
                                            <label className='text-sm'>Pin code / Zip code :</label>
                                            <input 
                                             type="text"
                                           name="zipCode"
                                             value={formData.zipCode}
                                               onChange={handleChange}
                                            
                                            className='border-b border-dashed outline-none w-[100%] bg-[#232323]'></input>
                                        </div>
                                        <div className='flex flex-col md:flex-row gap-5'>
                                            <div className='w-[100%] md:w-[50%]'>
                                                <label className='text-sm'>Are you here as :</label>
                                                <Select
                                                    className="w-[100%]"
                                                    value={Purpose}
                                                    onChange={handlePurpose}
                                                    options={Selectpurpose}
                                                    styles={customStyles}
                                                    placeholder="Prefer one"
                                                />
                                            </div>
                                            <div className='w-[100%] md:w-[50%]'>
                                                <label className='text-sm'>Referral Code (optional)</label>
                                                <input
                                                 className='border-b border-dashed outline-none w-[100%] bg-[#232323] h-[38px]'
                                                     type="text"
                                                     name="referCode"
                                                     value={formData.referCode}
                                                     onChange={handleChange}
                                                     ></input>
                                            </div>
                                        </div>
                                        <div className='flex justify-center'>
                                            {/* <ReCAPTCHA
                                                 ref={recaptchaRef}
                                                sitekey={reCaptcha_public}
                                                onChange={handleRecaptchaChange}
                                                className="scale-90"
                                            /> */}

                                <RecaptchaComponent 
                                            ref={recaptchaRef}

                                        onTokenGenerated={handleRecaptchaChange}
                                        onTokenExpired={handleTokenExpiration}
                                        />

                                        </div>
                                    </div>
                                    <div className='flex flex-col md:flex-row gap-5 md:gap-0 items-center justify-between mt-5 font-semibold'>
                                        <h1 className='text-lg '>Price: <span className='bg-gradient-to-r from-[#0EA4A9] to-[#5D6EF3] bg-clip-text text-transparent'> {selectedCurrency.value} {convertedPrice}</span></h1>
                                        <Select
                                            value={selectedCurrency}
                                            onChange={handleCurrencyChange}
                                            options={currencyOptions}
                                            styles={customStyles}
                                            placeholder="Select Currency"
                                        />
                                        <button onClick={handleSubmission} className='font-play bg-[#d9d9d9]/35 backdrop-blur-md py-2 px-5 rounded-full duration-500 active:translate-y-1'>
                                            Book my kit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {ConfirmSubmission && (
                            <div data-aos='fade-in' data-aos-duration='1000' className='fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/80 z-50'>
                                <div data-aos='zoom-in' data-aos-duration='2000' className='w-[95%] md:w-[80%] 2xl:min-h-[75vh] grid content-center bg-[#FBF6F6]/10 backdrop-blur-3xl rounded-2xl p-5 md:p-10 text-center font-semibold text-base 2xl:text-lg'>
                                    <div className='flex justify-center'>
                                        <h1 className='font-bold bg-gradient-to-r from-[#31989B] to-[#F29D38] bg-clip-text text-transparent text-xl md:text-3xl'>Confirmation</h1>
                                    </div>
                                    <p className='mt-7 md:leading-10 md:px-5'>By proceeding with this booking, I understand that the results of this assessment will be securely stored on a blockchain, ensuring my exclusive ownership of the data. This guarantees that no third party, including the service provider, can access or modify my DNA report without my explicit authorization. I accept responsibility for completing the test process as outlined, and I agree to the privacy and data security protocols in place.</p>
                                    <p className='mt-5 md:mt-10'>Would you like to confirm your booking?</p>
                                    <div className='flex justify-center'>
                                        <button onClick={handleBookKit} className='mt-7 font-play bg-[#d9d9d9]/35 backdrop-blur-md py-2 px-5 rounded-full duration-500 active:translate-y-1'>
                                            Yes, Confirm my test kit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {ConfirmKit && (
                            <div data-aos='fade-in' data-aos-duration='1000' className='fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/80 z-50'>
                                <div data-aos='zoom-in' data-aos-duration='2000' className='w-[95%] md:w-[80%] 2xl:min-h-[75vh] grid content-center bg-[#FBF6F6]/10 backdrop-blur-3xl rounded-2xl p-5 md:p-10 text-center font-semibold text-base 2xl:text-lg'>
                                    <p>"<span className='text-[#F29D38]'>Thank you</span> for booking your DNA test kit! Your kit will be dispatched soon, and instructions will be provided inside. Once processed, your results will be securely stored on the<span className='text-[#F29D38]'> blockchain under your exclusive ownership</span>”</p>
                                    <img src='/assets/HeroPopup.png' data-aos='flip-right' className='w-[50%] md:w-[25%] mx-auto'></img>
                                    <p className='w-[100%] md:w-[50%] mt-5 md:mt-10 mx-auto'>Further details will be sent to your email.We appreciate your trust
                                        Thank you for choosing us!"</p>
                                    <div className='flex justify-center'>
                                        <NavLink to="/">
                                        <button 
                                        onClick={() => { handleKit(); handleSubmission() }}
                                         className='mt-7 font-play bg-[#d9d9d9]/35 backdrop-blur-md py-2 px-5 rounded-full duration-500 active:translate-y-1'>
                                            Go to Home
                                        </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
