import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4'; // Import ReactGA
import './index.css';

import Routers from './route';

const TRACKING_ID = "G-BM4L31Q8PG"; 

ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search, 
      title: "D5Art"
    });
  }, []);

  return (
    <Router>
      <Routers />
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


