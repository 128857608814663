import React from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function SliderTesty() {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute z-10 top-1/2 transform -translate-y-1/2 -left-5 w-10 h-10 rounded-full bg-white/10 cursor-pointer duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronLeft} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute top-1/2 transform -translate-y-1/2 -right-5 w-10 h-10 rounded-full bg-white/10 cursor-pointer  duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronRight} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const settingsTestimonial = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };

    return (
        <div className='w-[85%] mx-auto mt-10'>
            <Slider className="text-white block md:hidden" {...settingsTestimonial}>
                <div className="px-5 ">
                    <div className="bg-[#D9D9D91F] backdrop-blur-md grid content-between rounded-[30px] p-8">
                        <p>"D5art transformed my career! The unbiased hiring process truly showcases my skills, opening doors I never imagined possible."</p>
                        <div className="flex items-center mt-4">
                            <div>
                                <img src='/assets/mas1.png' className="w-32" />
                            </div>
                            <div>
                                <p className="text">D5art ID: <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]">0xhjwc9</span></p>
                                <p className="text-sm text-nowrap">Blockchain Developer</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-5 ">
                    <div className="bg-[#D9D9D91F] backdrop-blur-md grid content-between rounded-[30px] p-8">
                        <p>"The D5art Passport is a game-changer! It highlights my talents and connects me with incredible opportunities in the Web3 landscape."</p>
                        <div className="flex items-center mt-4">
                            <div>
                                <img src='/assets/mas2.png' className="w-32" />
                            </div>
                            <div>
                                <p className="text">D5art ID: <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]">0xhjwc9</span></p>
                                <p className="text-sm text-nowrap">Blockchain Developer</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-5 ">
                    <div className="bg-[#D9D9D91F] backdrop-blur-md grid content-between rounded-[30px] p-8">
                        <p>"Thanks to D5art, I've gained confidence in my abilities. The Skill Quest helped me hone my craft and land my dream job!"</p>
                        <div className="flex items-center mt-4">
                            <div>
                                <img src='/assets/mas3.png' className="w-32" />
                            </div>
                            <div>
                                <p className="text">D5art ID: <span className="text-transparent font-bold bg-clip-text bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]">0xhjwc9</span></p>
                                <p className="text-sm text-nowrap">Blockchain Developer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}
