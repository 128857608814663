
import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';

const RecaptchaComponent = forwardRef(({ onTokenGenerated, onTokenExpired }, ref) => {
  const [useCloudflare, setUseCloudflare] = useState(1);
  const recaptchaRef = useRef(null);
  const [widgetId, setWidgetId] = useState(null);
  const scriptLoaded = useRef(false);

  // Provide resetRecaptcha function to parent components
  useImperativeHandle(ref, () => ({
    resetRecaptcha: () => {
      if (useCloudflare && window.turnstile) {
        window.turnstile.reset();
      } else if (widgetId !== null && window.grecaptcha) {
        window.grecaptcha.reset(widgetId);
      }
    }
  }));

  useEffect(() => {
    const loadRecaptchaScript = () => {
      if (scriptLoaded.current) return;

      const script = document.createElement('script');
      script.async = true;
      script.onload = handleRecaptchaLoad;
      script.src = useCloudflare
        ? 'https://challenges.cloudflare.com/turnstile/v0/api.js'
        : 'https://www.google.com/recaptcha/api.js?render=explicit';
      document.body.appendChild(script);
      scriptLoaded.current = true;
    };

    loadRecaptchaScript();

    return () => {
      if (useCloudflare && window.turnstile) {
        window.turnstile.reset();
      } else if (widgetId !== null && window.grecaptcha) {
        window.grecaptcha.reset(widgetId);
      }
    };
  }, [useCloudflare]);

  const handleRecaptchaLoad = () => {
    if (useCloudflare && window.turnstile) {
      window.turnstile.render(recaptchaRef.current, {
        sitekey: '0x4AAAAAAAzhdXT8tcR1OAVr',
        callback: onTokenGenerated,
        'expired-callback': onTokenExpired,
      });
    } else if (!useCloudflare && window.grecaptcha) {
      const id = window.grecaptcha.render(recaptchaRef.current, {
        sitekey: '6LeDPCoqAAAAAH7ABnqmmz3q7YvSZIXZxbRE4mN2',
        callback: onTokenGenerated,
        'expired-callback': onTokenExpired,
      });
      setWidgetId(id);
    }
  };

  return<div ref={recaptchaRef} id="recaptcha-container"></div>;
});

export default RecaptchaComponent;
