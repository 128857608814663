import React, { useEffect } from 'react';


const NotFound = () => {
  useEffect(() => {
    const createStar = () => {
      const body = document.body;
      const right = Math.random() * 500;
      const top = Math.random() * window.innerHeight;
      const star = document.createElement('div');
      star.style.position = 'absolute';
      star.style.width = '2px';
      star.style.height = '2px';
      star.style.background = '#fff';
      star.style.right = '0';
      star.style.animation = 'starTwinkle 3s infinite linear';
      star.style.top = `${top}px`;
      body.appendChild(star);

      const runStar = setInterval(() => {
        if (parseInt(star.style.right, 10) >= window.innerWidth) {
          star.remove();
          clearInterval(runStar);
        } else {
          star.style.right = `${parseInt(star.style.right, 10) + 3}px`;
        }
      }, 10);
    };

    const intervalId = setInterval(createStar, 100);
    return () => clearInterval(intervalId);
  }, []);

  const bodyStyles = {
    margin: 0,
    padding: 0,
    fontFamily: "'Tomorrow', sans-serif",
    height: '100vh',
    backgroundImage: 'linear-gradient(to top, #2e1753, #1f1746, #131537, #0d1028, #050819)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const textStyles = {
    position: 'absolute',
    top: '10%',
    color: '#fff',
    textAlign: 'center',
  };

  const h1Styles = {
    fontSize: '50px',
  };

  const astronautImgStyles = {
    width: '100px',
    position: 'absolute',
    top: '55%',
    animation: 'astronautFly 6s infinite linear',
  };

  const keyframes = `
    @keyframes astronautFly {
      0% {
        left: -100px;
      }
      25% {
        top: 50%;
        transform: rotate(30deg);
      }
      50% {
        transform: rotate(45deg);
        top: 55%;
      }
      75% {
        top: 60%;
        transform: rotate(30deg);
      }
      100% {
        left: 110%;
        transform: rotate(45deg);
      }
    }
    @keyframes starTwinkle {
      0% {
        background: rgba(255, 255, 255, 0.4);
      }
      25% {
        background: rgba(255, 255, 255, 0.8);
      }
      50% {
        background: rgba(255, 255, 255, 1);
      }
      75% {
        background: rgba(255, 255, 255, 0.8);
      }
      100% {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  `;

  return (
    <div style={bodyStyles}>
      <style>{keyframes}</style>
      <div className="text" style={textStyles}>
        <div>ERROR</div>
        <h1 style={h1Styles}>404</h1>
        <hr />
        <div>Page Not Found</div>
      </div>
      <div className="astronaut">
        <img
          src="https://images.vexels.com/media/users/3/152639/isolated/preview/506b575739e90613428cdb399175e2c8-space-astronaut-cartoon-by-vexels.png"
          alt="Astronaut"
          style={astronautImgStyles}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-center gap-7  ">
            <a href="https://d5art.com/" className="mx-auto md:mx-0">
              <button className="bg-gradient-to-r from-[#00768B] to-[#0E2DA7] rounded-full p-[2px]">
                <button className="bg-[#1A1919] px-5 py-2 w-[200px] rounded-full text-white font-museomoderno">Back To Home </button>
              </button>
            </a>
            {/* <a href="https://ex.d5art.com/Register" className="mx-auto md:mx-0">
              <button className="bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00] rounded-full p-[2px]">
                <button className="bg-[#1A1919] px-5 py-2 w-[200px] rounded-full text-white font-museomoderno">Explore Skill Realm</button>
              </button>
            </a> */}
          </div>
    </div>
  );
};

export default NotFound;


